import React, { Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CoffeeMachineSVG from "./components/layout/jura-coffee-machine";

import "babel-polyfill";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

import Home from "./components/Home";
import Call from "./components/Call";
// import MenuScreen from "./components/MenuScreen";
import JuraLive from "./components/JuraLive";
import Consultant from "./components/Consultant";
import AboutJura from "./components/AboutJura";
import Tech from "./components/Tech";
import Campaign from "./components/Campaign";
import News from "./components/News";
import Feedback from "./components/Feedback";
import Thanks from "./components/Thanks";
import FlagScreen from "./components/FlagScreen";
import FlagScreenQR from "./components/FlagScreenQR";
import FlagScreenQR2 from "./components/FlagScreenQR2";
import QrCodeScreen from "./components/QrCodeScreen";
import Thermo from "./components/Thermo";
import Resultat from "./components/Resultat";

// Redux
import { Provider } from "react-redux";
import store from "./store";

function App() {
  const [isOnline, setIsOnline] = useState(true);

  // Checks to see if the browser has internet connection or not
  window.addEventListener("online", () => setIsOnline(true));
  window.addEventListener("offline", () => setIsOnline(false));

  return isOnline ? (
    <Provider store={store}>
      <Suspense fallback="loading">
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/call" component={Call} />
            {/* <Route exact path="/menu-screen" component={MenuScreen} /> */}
            <Route exact path="/jura-live" component={JuraLive} />
            <Route exact path="/consultant" component={Consultant} />
            <Route exact path="/about-jura" component={AboutJura} />
            <Route exact path="/tech" component={Tech} />
            <Route exact path="/news" component={News} />
            <Route exact path="/campaign" component={Campaign} />
            <Route exact path="/feedback" component={Feedback} />
            <Route exact path="/thanks" component={Thanks} />
            <Route exact path="/flag-screen" component={FlagScreen} />
            <Route exact path="/flag-screen-qr" component={FlagScreenQR} />
            <Route exact path="/flag-screen-qr2" component={FlagScreenQR2} />
            <Route exact path="/qrcode" component={QrCodeScreen} />
            <Route exact path="/thermo" component={Thermo} />
            <Route exact path="/resultat" component={Resultat} />
          </Switch>
        </Router>
      </Suspense>
    </Provider>
  ) : (
    <CoffeeMachineSVG />
  );
}

export default App;
