import React, { useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import Header1 from "../components/layout/Header1";
import BottomMenu2 from "./layout/BottomMenu2";
import LanguageSelector from "./layout/LanguageSelector";
import { MOVE_TO_HOMEPAGE_IN_SECONDS_LONG, getLangCode } from "../Utils/utils";
import IdleTimer from "react-idle-timer";

const News = ({ t, i18n, history }) => {
  const page = "news";

  let idleTimer = null;

  let id1;

  let btn1 = useRef();

  const img1 = useRef();

  const videoRef1 = useRef();

  useEffect(() => {
    videoRef1.current.volume = 0.1;
    videoRef1.current.play();

    return () => {
      clearTimeout(id1);
    };
  }, []);

  const playVideo = videoNumber => {
    switch (videoNumber) {
      case "first":
        videoRef1.current.play();
        // btn1.current.style.display = "none";
        // id1 = setTimeout(() => {
        //   img1.current.style.display = "none";
        // }, 4900);
        break;
      // case "second":
      //   videoRef2.current.play();
      //   btn2.current.style.display = "none";
      //   id2 = setTimeout(() => {
      //     img2.current.style.display = "none";
      //   }, 4800);
      //   break;
      // case "third":
      //   videoRef3.current.play();
      //   btn3.current.style.display = "none";
      //   id3 = setTimeout(() => {
      //     img3.current.style.display = "none";
      //   }, 4800);
      //   break;

      default:
        break;
    }
  };

  const onSelectItem = e => {
    switch (e) {
      case 0:
        videoRef1.current.currentTime = 0;
        videoRef1.current.play();
        // videoRef2.current.pause();
        // videoRef3.current.pause();
        break;
      // case 1:
      //   videoRef2.current.currentTime = 0;
      //   videoRef1.current.pause();
      //   videoRef3.current.pause();
      //   break;
      // case 2:
      //   videoRef3.current.currentTime = 0;
      //   videoRef2.current.pause();
      //   videoRef1.current.pause();
      //   break;
      default:
        break;
    }
  };

  const onIdle = e => {
    i18n.changeLanguage(getLangCode(window.machineName));
    history.push("/");
  };

  return (
    <div className="full-screen" style={{ backgroundColor: "#F6F8F9" }}>
      <IdleTimer
        ref={ref => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={MOVE_TO_HOMEPAGE_IN_SECONDS_LONG}
      />
      <LanguageSelector slideShow={true} />
      {/* <Header1 headerText={t("menu-news")} /> */}
      <Carousel
        id="carousel"
        indicators={false}
        interval={false}
        onSelect={e => {
          onSelectItem(e);
        }}
        nextIcon={
          <img
            src={require("../assets/chevron_right.png")}
            alt="next slide"
            style={{
              backgroundColor: "#2e2e2e",
              position: "absolute",
              right: "-8px",
              width: "80px",
              height: "80px",
              opacity: "0.9 !important"
            }}
          />
        }
        prevIcon={
          <img
            src={require("../assets/chevron_left.png")}
            alt="previous slide"
            style={{
              backgroundColor: "#2e2e2e",
              position: "absolute",
              left: "-8px",
              width: "80px",
              height: "80px",
              opacity: "0.9 !important"
            }}
          />
        }
      >
        <Carousel.Item style={{ width: "100vw", height: "100vh" }}>
          {/* <img
            ref={img1}
            src={require("../assets/RF_Spot.jpg")}
            width="100%"
            height="100%"
          />
          <button
            ref={btn1}
            style={{
              position: "absolute",
              top: "40%",
              left: "50%",
              background: `url(${require("../assets/play-btn.png")}) no-repeat`,
              width: "100px",
              height: "100px",
              border: "none"
            }}
            onClick={e => playVideo("first")}
          ></button> */}
          <video
            ref={videoRef1}
            poster={require("../assets/RF_Spot.jpg")}
            style={{
              objectFit: "cover",
              minWidth: "100vw",
              minHeight: "80vh",
              width: "auto",
              height: "91vh"
            }}
            controls
            controlsList="nofullscreen nodownload"
            preload="true"
            loop
          >
            <source
              src={
                window.screen.availHeight === 1080
                  ? require("../assets/video/Jura_NY_16x9_E_1.mp4")
                  : require("../assets/video/Jura_NY_4x3_E_1_1.mp4")
              }
              type="video/mp4"
            />
          </video>

          {/* <Carousel.Caption className="animated bounceInDown delay-2s">
            <h3>{t("about-jura-t2")}</h3>
          </Carousel.Caption>
          <Carousel.Caption className="animated bounceInDown delay-3s">
            <p>{t("about-jura-p2")}</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        {/* <Carousel.Item style={{ width: "100vw", height: "100vh" }}>
          <img
            ref={img2}
            src={require("../assets/de_Intelligent_Water_System.jpg")}
            width="100%"
            height="100%"
          />
          <button
            ref={btn2}
            style={{
              position: "absolute",
              top: "40%",
              left: "50%",
              background: `url(${require("../assets/play-btn.png")}) no-repeat`,
              width: "100px",
              height: "100px",
              border: "none"
            }}
            onClick={e => playVideo("second")}
          ></button>
          <video
            ref={videoRef2}
            style={{
              objectFit: "cover",
              minWidth: "100vw",
              minHeight: "80vh",
              width: "auto",
              height: "91vh"
            }}
            controls
            controlsList="nofullscreen nodownload"
            preload="true"
            loop
          >
            <source
              src={require("../assets/video/en_Intelligent_Water_System.mp4")}
              type="video/mp4"
            />
          </video>
        </Carousel.Item>
        <Carousel.Item style={{ width: "100vw", height: "100vh" }}>
          <img
            ref={img3}
            src={require("../assets/de_IPBAS_und_PEP.jpg")}
            width="100%"
            height="100%"
          />
          <button
            ref={btn3}
            style={{
              position: "absolute",
              top: "40%",
              left: "50%",
              background: `url(${require("../assets/play-btn.png")}) no-repeat`,
              width: "100px",
              height: "100px",
              border: "none"
            }}
            onClick={e => playVideo("third")}
          ></button>
          <video
            ref={videoRef3}
            poster={require("../assets/de_IPBAS_und_PEP.jpg")}
            style={{
              objectFit: "cover",
              minWidth: "100vw",
              minHeight: "80vh",
              width: "auto",
              height: "91vh"
            }}
            controls
            controlsList="nofullscreen nodownload"
            preload="true"
            loop
          >
            <source
              src={require("../assets/video/en_IPBAS_und_PEP.mp4")}
              type="video/mp4"
            />
          </video>
        </Carousel.Item> */}
      </Carousel>
      <BottomMenu2 slideShow page={page} />
    </div>
  );
};

export default withRouter(withTranslation()(News));
