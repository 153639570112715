import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import Header1 from "./layout/Header1";
import BottomMenu2 from "./layout/BottomMenu2";
import { MOVE_TO_HOMEPAGE_IN_SECONDS, getLangCode } from "../Utils/utils";
import IdleTimer from "react-idle-timer";

const Consultant = ({ t, i18n, history, ...props }) => {
  const page = "consultant";

  let idleTimer = null;

  const [sectionOne, setSectionOne] = useState([]);
  const [sectionTwo, setSectionTwo] = useState([]);

  const next1 = useRef();

  const section1Div = useRef({});
  const section2Div = useRef({});
  const section3Div = useRef({});

  const espressoDiv = useRef({});
  const kaffeeDiv = useRef({});
  const cappuccinoDiv = useRef({});
  const latteDiv = useRef({});
  const flatDiv = useRef({});
  const heisswasserDiv = useRef({});

  const cups5Div = useRef({});
  const cups10Div = useRef({});
  const cups20Div = useRef({});

  useEffect(() => {
    next1.current.style.opacity = 0.6;
    next1.current.disabled = true;
  }, []);

  const onCoffeeClick = (e, itemClicked) => {
    switch (itemClicked) {
      case "espresso":
        if (!sectionOne.includes("espresso")) {
          setSectionOne([...sectionOne, "espresso"]);
          espressoDiv.current.childNodes[0].style.opacity = 1.0;
          espressoDiv.current.childNodes[1].setAttribute(
            "style",
            "background-color: #666d7b; color: #f7f3ea"
          );
          next1.current.style.opacity = 1.0;
          next1.current.disabled = false;
        } else {
          setSectionOne(sectionOne.filter(item => item !== "espresso"));
          espressoDiv.current.childNodes[0].style.opacity = 0.4;
          espressoDiv.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
        }
        break;
      case "kaffee":
        if (!sectionOne.includes("kaffee")) {
          setSectionOne([...sectionOne, "kaffee"]);
          kaffeeDiv.current.childNodes[0].style.opacity = 1.0;
          kaffeeDiv.current.childNodes[1].setAttribute(
            "style",
            "background-color: #666d7b; color: #f7f3ea"
          );
          next1.current.style.opacity = 1.0;
          next1.current.disabled = false;
        } else {
          setSectionOne(sectionOne.filter(item => item !== "kaffee"));
          kaffeeDiv.current.childNodes[0].style.opacity = 0.4;
          kaffeeDiv.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
        }
        break;
      case "cappuccino":
        if (!sectionOne.includes("cappuccino")) {
          setSectionOne([...sectionOne, "cappuccino"]);
          cappuccinoDiv.current.childNodes[0].style.opacity = 1.0;
          cappuccinoDiv.current.childNodes[1].setAttribute(
            "style",
            "background-color: #666d7b; color: #f7f3ea"
          );
          next1.current.style.opacity = 1.0;
          next1.current.disabled = false;
        } else {
          setSectionOne(sectionOne.filter(item => item !== "cappuccino"));
          cappuccinoDiv.current.childNodes[0].style.opacity = 0.4;
          cappuccinoDiv.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
        }
        break;
      case "latte":
        if (!sectionOne.includes("latte")) {
          setSectionOne([...sectionOne, "latte"]);
          latteDiv.current.childNodes[0].style.opacity = 1.0;
          latteDiv.current.childNodes[1].setAttribute(
            "style",
            "background-color: #666d7b; color: #f7f3ea"
          );
          next1.current.style.opacity = 1.0;
          next1.current.disabled = false;
        } else {
          setSectionOne(sectionOne.filter(item => item !== "latte"));
          latteDiv.current.childNodes[0].style.opacity = 0.4;
          latteDiv.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
        }
        break;
      case "flat":
        if (!sectionOne.includes("flat")) {
          setSectionOne([...sectionOne, "flat"]);
          flatDiv.current.childNodes[0].style.opacity = 1.0;
          flatDiv.current.childNodes[1].setAttribute(
            "style",
            "background-color: #666d7b; color: #f7f3ea"
          );
          next1.current.style.opacity = 1.0;
          next1.current.disabled = false;
        } else {
          setSectionOne(sectionOne.filter(item => item !== "flat"));
          flatDiv.current.childNodes[0].style.opacity = 0.4;
          flatDiv.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
        }
        break;
      case "heisswasser":
        if (!sectionOne.includes("heisswasser")) {
          setSectionOne([...sectionOne, "heisswasser"]);
          heisswasserDiv.current.childNodes[0].style.opacity = 1.0;
          heisswasserDiv.current.childNodes[1].setAttribute(
            "style",
            "background-color: #666d7b; color: #f7f3ea"
          );
          next1.current.style.opacity = 1.0;
          next1.current.disabled = false;
        } else {
          setSectionOne(sectionOne.filter(item => item !== "heisswasser"));
          heisswasserDiv.current.childNodes[0].style.opacity = 0.4;
          heisswasserDiv.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
        }
        break;
      default:
        break;
    }
  };

  const onCupsClick = (e, cups) => {
    switch (cups) {
      case "cups5":
        if (!sectionTwo.includes("cups5")) {
          cups5Div.current.setAttribute("style", "opacity: 1 !important");
          cups5Div.current.childNodes[1].setAttribute(
            "style",
            "background-color: #666d7b; color: #f7f3ea"
          );
          cups10Div.current.setAttribute("style", "opacity: 0.4 !important");
          cups10Div.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
          cups20Div.current.setAttribute("style", "opacity: 0.4 !important");
          cups20Div.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
          sectionTwo.length = 0;
          setSectionTwo([...sectionTwo, "cups5"]);
        } else {
          setSectionTwo(sectionTwo.filter(item => item !== "cups5"));
          cups5Div.current.setAttribute("style", "opacity: 0.4 !important");
          cups5Div.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
        }
        break;
      case "cups10":
        if (!sectionTwo.includes("cups10")) {
          cups10Div.current.setAttribute("style", "opacity: 1 !important");
          cups10Div.current.childNodes[1].setAttribute(
            "style",
            "background-color: #666d7b; color: #f7f3ea"
          );
          cups5Div.current.setAttribute("style", "opacity: 0.4 !important");
          cups5Div.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
          cups20Div.current.setAttribute("style", "opacity: 0.4 !important");
          cups20Div.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
          sectionTwo.length = 0;
          setSectionTwo([...sectionTwo, "cups10"]);
        } else {
          setSectionTwo(sectionTwo.filter(item => item !== "cups10"));
          cups10Div.current.setAttribute("style", "opacity: 0.4 !important");
          cups10Div.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
        }
        break;
      case "cups20":
        if (!sectionTwo.includes("cups20")) {
          cups20Div.current.setAttribute("style", "opacity: 1 !important");
          cups20Div.current.childNodes[1].setAttribute(
            "style",
            "background-color: #666d7b; color: #f7f3ea"
          );
          cups5Div.current.setAttribute("style", "opacity: 0.4 !important");
          cups5Div.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
          cups10Div.current.setAttribute("style", "opacity: 0.4 !important");
          cups10Div.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
          sectionTwo.length = 0;
          setSectionTwo([...sectionTwo, "cups20"]);
        } else {
          setSectionTwo(sectionTwo.filter(item => item !== "cups20"));
          cups20Div.current.setAttribute("style", "opacity: 0.4 !important");
          cups20Div.current.childNodes[1].setAttribute(
            "style",
            "background-color: #f7f3ea; color: #666d7b"
          );
        }
        break;
      default:
        break;
    }
  };

  const reset = e => {
    next1.current.style.opacity = 0.6;
    next1.current.disabled = true;
    section1Div.current.style.display = "block";
    section2Div.current.style.display = "none";
    section3Div.current.style.display = "none";
    espressoDiv.current.childNodes[0].style.opacity = 0.4;
    espressoDiv.current.childNodes[1].setAttribute(
      "style",
      "background-color: #f7f3ea; color: #666d7b"
    );
    kaffeeDiv.current.childNodes[0].style.opacity = 0.4;
    kaffeeDiv.current.childNodes[1].setAttribute(
      "style",
      "background-color: #f7f3ea; color: #666d7b"
    );
    cappuccinoDiv.current.childNodes[0].style.opacity = 0.4;
    cappuccinoDiv.current.childNodes[1].setAttribute(
      "style",
      "background-color: #f7f3ea; color: #666d7b"
    );
    latteDiv.current.childNodes[0].style.opacity = 0.4;
    latteDiv.current.childNodes[1].setAttribute(
      "style",
      "background-color: #f7f3ea; color: #666d7b"
    );
    flatDiv.current.childNodes[0].style.opacity = 0.4;
    flatDiv.current.childNodes[1].setAttribute(
      "style",
      "background-color: #f7f3ea; color: #666d7b"
    );
    heisswasserDiv.current.childNodes[0].style.opacity = 0.4;
    heisswasserDiv.current.childNodes[1].setAttribute(
      "style",
      "background-color: #f7f3ea; color: #666d7b"
    );
    cups5Div.current.setAttribute("style", "opacity: 0.4 !important");
    cups5Div.current.childNodes[1].setAttribute(
      "style",
      "background-color: #f7f3ea; color: #666d7b"
    );
    cups10Div.current.setAttribute("style", "opacity: 0.4 !important");
    cups10Div.current.childNodes[1].setAttribute(
      "style",
      "background-color: #f7f3ea; color: #666d7b"
    );
    cups20Div.current.setAttribute("style", "opacity: 0.4 !important");
    cups20Div.current.childNodes[1].setAttribute(
      "style",
      "background-color: #f7f3ea; color: #666d7b"
    );
  };

  const goToSection2 = e => {
    section1Div.current.style.display = "none";
    section2Div.current.style.display = "block";
    section3Div.current.style.display = "none";
  };

  const goToSection1 = e => {
    section1Div.current.style.display = "block";
    section2Div.current.style.display = "none";
    section3Div.current.style.display = "none";
  };

  const goToSection3 = e => {
    section1Div.current.style.display = "none";
    section2Div.current.style.display = "none";
    section3Div.current.style.display = "block";
  };

  const onIdle = e => {
    i18n.changeLanguage(getLangCode(window.machineName));
    history.push("/");
  };

  return (
    <div className="full-screen" style={{ backgroundColor: "#ffffff" }}>
      <IdleTimer
        ref={ref => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={MOVE_TO_HOMEPAGE_IN_SECONDS}
      />
      <Header1 headerText={t("menu-consultant")} />
      <div id="section1" ref={section1Div}>
        <Row style={{ height: "75vh" }}>
          <Col sm="6" className="align-vertical">
            <Row>
              <Col>
                <span className="consultant-text1">
                  {t("consultant-s1-t1")}
                </span>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <span className="consultant-text2">
                  {t("consultant-s1-p1")}
                </span>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col sm="4">
                <button className="cbtn" onClick={e => history.push("/")}>
                  {t("consultant-cancel-btn")}
                </button>
              </Col>
              <Col>
                <button
                  ref={next1}
                  className="cbtn btn-next"
                  onClick={e => goToSection2(e)}
                >
                  {t("consultant-next-btn1")}
                </button>
              </Col>
            </Row>
          </Col>
          <Col sm="6" className="align-vertical">
            <Row className="text-center mb-2">
              <Col>
                <span className="consultant-text3">
                  {t("consultant-s1-t2")}
                </span>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col>
                <div
                  id="espresso"
                  ref={espressoDiv}
                  className="coffee-image text-center shadow"
                  onClick={e => onCoffeeClick(e, "espresso")}
                >
                  <img src={require("../assets/espresso.png")} alt="espresso" />
                  <span>{t("consultant-c1")}</span>
                </div>
              </Col>
              <Col>
                <div
                  id="kaffee"
                  ref={kaffeeDiv}
                  className="coffee-image text-center shadow"
                  onClick={e => onCoffeeClick(e, "kaffee")}
                >
                  <img src={require("../assets/kaffee.png")} alt="Kaffee" />
                  <span>{t("consultant-c2")}</span>
                </div>
              </Col>
              <Col>
                <div
                  id="cappuccino"
                  ref={cappuccinoDiv}
                  className="coffee-image text-center shadow"
                  onClick={e => onCoffeeClick(e, "cappuccino")}
                >
                  <img
                    src={require("../assets/cappaccino.png")}
                    alt="cappaccino"
                  />
                  <span>{t("consultant-c3")}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  id="latte"
                  ref={latteDiv}
                  className="coffee-image text-center shadow"
                  onClick={e => onCoffeeClick(e, "latte")}
                >
                  <img
                    src={require("../assets/latte-macchiato.png")}
                    alt="latte-macchiato"
                  />
                  <span>{t("consultant-c4")}</span>
                </div>
              </Col>
              <Col>
                <div
                  id="flat"
                  ref={flatDiv}
                  className="coffee-image text-center shadow"
                  onClick={e => onCoffeeClick(e, "flat")}
                >
                  <img
                    src={require("../assets/flat-white.png")}
                    alt="flat-white"
                  />
                  <span>{t("consultant-c5")}</span>
                </div>
              </Col>
              <Col>
                <div
                  ref={heisswasserDiv}
                  id="heisswasser"
                  className="coffee-image text-center shadow"
                  onClick={e => onCoffeeClick(e, "heisswasser")}
                >
                  <img
                    src={require("../assets/heisswasser.png")}
                    alt="heisswasser"
                  />
                  <span>{t("consultant-c6")}</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div id="section2" ref={section2Div}>
        <Row style={{ height: "75vh" }}>
          <Col sm="6" className="align-vertical">
            <Row>
              <Col>
                <span className="consultant-text1">
                  {t("consultant-s1-t1")}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="consultant-text2">
                  {t("consultant-s1-p1")}
                </span>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col sm="4">
                <button className="cbtn" onClick={e => goToSection1(e)}>
                  {t("consultant-bk-btn")}
                </button>
              </Col>
              <Col sm="4">
                <button
                  className="cbtn btn-next"
                  onClick={e => goToSection3(e)}
                >
                  {t("consultant-next-btn2")}
                </button>
              </Col>
            </Row>
          </Col>
          <Col sm="6" className="align-vertical">
            <Row className="mb-5 text-center">
              <Col>
                <span className="consultant-text3">
                  {t("consultant-s2-t3")}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  id="cups5"
                  ref={cups5Div}
                  className="coffee-image text-center shadow"
                  onClick={e => onCupsClick(e, "cups5")}
                >
                  <span className="ncups">{t("consultant-cup5")}</span>
                  <span>{t("consultant-s2-n1")}</span>
                </div>
              </Col>
              <Col>
                <div
                  id="cups10"
                  ref={cups10Div}
                  className="coffee-image text-center shadow"
                  onClick={e => onCupsClick(e, "cups10")}
                >
                  <span className="ncups">{t("consultant-cup10")}</span>
                  <span>{t("consultant-s2-n1")}</span>
                </div>
              </Col>
              <Col>
                <div
                  id="cups20"
                  ref={cups20Div}
                  className="coffee-image text-center shadow"
                  onClick={e => onCupsClick(e, "cups20")}
                >
                  <span className="ncups" style={{ top: "0.7em" }}>
                    {t("consultant-cup20")}
                  </span>
                  <span>{t("consultant-s2-n1")}</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div id="section3" ref={section3Div}>
        <Row style={{ height: "75vh" }}>
          <Col sm="6" className="align-vertical">
            <Row>
              <Col>
                <span className="consultant-text1">
                  {t("consultant-s3-t1")}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="consultant-text2">
                  {t("consultant-s3-p1")}
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="consultant-text2">
                  {t("consultant-s3-p2")}
                </span>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col sm="4">
                <button
                  className="cbtn btn-try-again"
                  onClick={e => history.push("/")}
                >
                  {t("consultant-back-home")}
                </button>
              </Col>
              <Col>
                <button className="cbtn btn-try-again" onClick={e => reset(e)}>
                  {t("consultant-try-again")}
                </button>
              </Col>
            </Row>
          </Col>
          <Col sm="6" className="align-vertical">
            <Row className="mb-5"></Row>
            <Row className="text-center">
              <Col sm="5">
                <img
                  src={require("../assets/Z6.png")}
                  alt="Jura Coffee Machine"
                  width="340px"
                  height="340px"
                />
                <span className="cm-desc">{t("consultant-s3-p3")}</span>
              </Col>
              <Col sm="5">
                <img
                  src={require("../assets/Z8.png")}
                  alt="Jura Coffee Machine"
                  width="340px"
                  height="340px"
                />
                <span className="cm-desc">{t("consultant-s3-p4")}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <BottomMenu2 page={page} />
    </div>
  );
};

export default withRouter(withTranslation()(Consultant));
