import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Iframe from "react-iframe";
import { withTranslation } from "react-i18next";
import { getLangCode } from "../../Utils/utils";

const CallModal = (props) => {
  const { t, i18n, history, tReady, to, staticContext, ...rest } = props;
  const callUrl = props.location.state.url;

  const [loading, setLoading] = useState(true);

  console.log("url coming in: ", props.location.state.url);

  useEffect(() => {
    window.addEventListener("message", (msg) => {
      if (msg.data.close) {
        i18n.changeLanguage(getLangCode(window.machineName));
        history.push("/");
      }
    });
  }, []);

  const hideSpinner = () => {
    setLoading(false);
  };

  const removeIFrame = () => {
    const frame = document.getElementById("jura-live-iframe");
    frame.parentNode.removeChild(frame);
  };

  const handleClick = (e) => {
    props.onHide();
    removeIFrame();
    i18n.changeLanguage(getLangCode(window.machineName));
    history.push("/");
  };

  // Redirects to Homepage when X button pressed
  const closeModalPressed = () => {
    props.onHide();
    i18n.changeLanguage(getLangCode(window.machineName));
    history.push("/");
  };

  return (
    <Modal
      {...rest}
      centered
      animation={true}
      backdrop={"static"}
      dialogClassName="jura-live-modal"
      onHide={(e) => closeModalPressed()}
    >
      <Modal.Header closeButton></Modal.Header>
      {loading ? (
        <Spinner
          animation="border"
          variant="primary"
          style={{ position: "absolute", top: "45%", left: "50%" }}
        />
      ) : null}
      <Iframe
        url={callUrl}
        id="jura-live-iframe"
        display="initial"
        className="jura-live-iframe"
        onLoad={hideSpinner}
        allow="geolocation; microphone; camera;"
      />
      {!loading ? (
        <Modal.Footer>
          <button className="btn-cancel-call" onClick={(e) => handleClick()}>
            {t("call-close-modal")}
          </button>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default withRouter(withTranslation()(CallModal));
