import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import CallModal from './layout/CallModal';
import Header1 from './layout/Header1';
import BottomMenu2 from './layout/BottomMenu2';
import { WAIT_BEFORE_MAKING_CALL } from '../Utils/utils';
import Timer from 'react-compound-timer';

const Call = ({ t, i18n, history }) => {
  const [modalShow, setModalShow] = useState(false);
  const modalTimer = useRef(null);

  // const animatedGif = useRef() ;

  useEffect(() => {
    modalTimer.current = setTimeout(() => setModalShow(true), WAIT_BEFORE_MAKING_CALL);
    // animatedGif.current.src = require('../assets/animated-gif/jura-live.gif');
    // return () => {
    //   animatedGif.current.src = require('../assets/animated-gif/jura-live.gif');
    // };
  }, []);

  const handleCancelCall = (e) => {
    modalTimer.current && clearTimeout(modalTimer.current);
    history.push('/');
  };

  return (
    <div className='full-screen'>
      <CallModal show={modalShow} onHide={() => setModalShow(false)} />
      <Header1 headerText={t('welcome-jura-live')} />
      <Row
        style={{
          backgroundColor: '#F6F8F9',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '85vh',
          minHeight: '500px',
        }}
      >
        <Col sm='5'>
          <span className='call-text1'>{t('call-text-1')}</span>
          <span className='call-text2'>{t('call-text-2')}</span>
          <span className='call-text3'>{t('call-text-3')}</span>
          <button type='button' className='call-cancel-btn' onClick={(e) => handleCancelCall()}>
            <img
              alt='3d rotation'
              style={{
                width: '50px',
                height: '50px',
                position: 'absolute',
                left: '25px',
                bottom: '8px',
              }}
              src={require('../assets/3d_rotation.png')}
            />
            <span style={{ position: 'absolute', left: '43px', top: '30px' }}>
              <Timer initialTime={9000} direction='backward'>
                {() => (
                  <React.Fragment>
                    <Timer.Seconds />
                  </React.Fragment>
                )}
              </Timer>
            </span>
            <span>{t('call-btn-cancel')}</span>
          </button>
        </Col>
        <Col sm='7'>
          {/* <div
            style={{
              position: 'absolute',
              top: '16%',
              right: '12%',
              width: window.screen.availHeight === 1080 ? '950px' : '800px',
              height: '535px',
              borderTop: '30px solid #666d7b',
              borderLeft: '30px solid #666d7b',
            }}
          ></div> */}
          {/* <img
            ref={animatedGif}
            alt='Calling Jura Live Studio'
            width='95%'
            style={{
              position: 'absolute',
              top: '18%',
              right: '10%',
              width: window.screen.availHeight === 1080 ? '950px' : '800px',
              height: '535px',
            }}
          /> */}
          <video
            // poster={require('../assets/main/img/hot-cold.png')}
            style={{
              objectFit: 'contain',
              minHeight: '74vh',
              minWidth: '55vw',
              width: '55vw',
              height: window.screen.availHeight === 1080 ? '74vh' : '77vh',
            }}
            // controls
            autoPlay
            muted
            controlsList='nofullscreen nodownload'
            preload='true'
            disablePictureInPicture
          >
            <source src={require('../assets/main/vids/JURA_LIVE_Intro_15s_low.mp4')} type='video/mp4' />
          </video>
        </Col>
      </Row>
      <BottomMenu2 />
    </div>
  );
};

export default withTranslation()(Call);

// import React, { useState, useEffect, useRef } from 'react';
// import { Row, Col } from 'react-bootstrap';
// import { withTranslation } from 'react-i18next';
// import CallModal from './layout/CallModal';
// import Header1 from './layout/Header1';
// import BottomMenu2 from './layout/BottomMenu2';
// import { WAIT_BEFORE_MAKING_CALL } from '../Utils/utils';
// import Timer from 'react-compound-timer';

// const Call = ({ t, i18n, history }) => {
//   const [modalShow, setModalShow] = useState(false);
//   const modalTimer = useRef(null);

//   const animatedGif = useRef();

//   useEffect(() => {
//     modalTimer.current = setTimeout(() => setModalShow(true), WAIT_BEFORE_MAKING_CALL);
//     animatedGif.current.src = require('../assets/animated-gif/jura-live.gif');
//     return () => {
//       animatedGif.current.src = require('../assets/animated-gif/jura-live.gif');
//     };
//   }, []);

//   const handleCancelCall = (e) => {
//     modalTimer.current && clearTimeout(modalTimer.current);
//     history.push('/');
//   };

//   return (
//     <div className='full-screen'>
//       <CallModal show={modalShow} onHide={() => setModalShow(false)} />
//       <Header1 headerText={t('welcome-jura-live')} />
//       <Row
//         style={{
//           backgroundColor: '#F6F8F9',
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//           backgroundRepeat: 'no-repeat',
//           height: '85vh',
//           minHeight: '500px',
//         }}
//       >
//         <Col sm='5'>
//           <span className='call-text1'>{t('call-text-1')}</span>
//           <span className='call-text2'>{t('call-text-2')}</span>
//           <span className='call-text3'>{t('call-text-3')}</span>
//           <button type='button' className='call-cancel-btn' onClick={(e) => handleCancelCall()}>
//             <img
//               style={{
//                 width: '50px',
//                 height: '50px',
//                 position: 'absolute',
//                 left: '25px',
//                 bottom: '8px',
//               }}
//               src={require('../assets/3d_rotation.png')}
//             />
//             <span style={{ position: 'absolute', left: '43px', top: '30px' }}>
//               <Timer initialTime={9000} direction='backward'>
//                 {() => (
//                   <React.Fragment>
//                     <Timer.Seconds />
//                   </React.Fragment>
//                 )}
//               </Timer>
//             </span>
//             <span>{t('call-btn-cancel')}</span>
//           </button>
//         </Col>
//         <Col sm='7'>
//           <div
//             style={{
//               position: 'absolute',
//               top: '16%',
//               right: '12%',
//               width: window.screen.availHeight === 1080 ? '950px' : '800px',
//               height: '535px',
//               borderTop: '30px solid #666d7b',
//               borderLeft: '30px solid #666d7b',
//             }}
//           ></div>
//           <img
//             ref={animatedGif}
//             alt='Calling Jura Live Studio'
//             width='95%'
//             style={{
//               position: 'absolute',
//               top: '18%',
//               right: '10%',
//               width: window.screen.availHeight === 1080 ? '950px' : '800px',
//               height: '535px',
//             }}
//           />
//         </Col>
//       </Row>
//       <BottomMenu2 />
//     </div>
//   );
// };

// export default withTranslation()(Call);
