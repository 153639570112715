import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Header1 from './layout/Header1';
import BottomMenu2 from './layout/BottomMenu2';
import { MOVE_TO_HOMEPAGE_IN_SECONDS_LONG, getLangCode } from '../Utils/utils';
import IdleTimer from 'react-idle-timer';

const JuraLive = ({ t, i18n, history, ...props }) => {
  const page = 'jura-live';
  const animatedGif = useRef();

  let idleTimer = null;

  useEffect(() => {
    // animatedGif.current.src = require('../assets/animated-gif/jura-live.gif');
    // return () => {
    //   animatedGif.current.src = require('../assets/animated-gif/jura-live.gif');
    // };
  }, []);

  const onIdle = (e) => {
    console.log('user is idle', e);
    console.log('last active', idleTimer.getLastActiveTime());
    i18n.changeLanguage(getLangCode(window.machineName));
    history.push('/');
  };

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        backgroundColor: '#F6F8F9',
      }}
    >
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={MOVE_TO_HOMEPAGE_IN_SECONDS_LONG}
      />
      <Header1 headerText={t('jura-live-what')} />
      <Row style={{ height: '70%', width: '100%' }}>
        <Col sm='4' className='align-vertical text-center'>
          <span
            className='jura-live-text1'
            style={{
              width: window.screen.availHeight === 1080 ? '13em' : '9em',
            }}
          >
            {t('jura-live-text1')}
          </span>
          <span className='jura-live-text2'>{t('jura-live-text2')}</span>
        </Col>
        <Col sm='8' className='align-vertical text-right'>
          {/* <img className='pr-4 pt-5' ref={animatedGif} width='80%' alt='Jura Live Studio' /> */}
          <video
            // poster={require('../assets/main/img/hot-cold.png')}
            style={{
              objectFit: 'contain',
              minHeight: '74vh',
              minWidth: '55vh',
              width: '55vw',
              height: window.screen.availHeight === 1080 ? '74vh' : '77vh',
            }}
            // controls
            autoPlay
            loop
            muted
            controlsList='nofullscreen nodownload'
            preload='true'
            disablePictureInPicture
          >
            <source src={require('../assets/main/vids/JURA_LIVE_Intro_15s_low.mp4')} type='video/mp4' />
          </video>
        </Col>
      </Row>
      <BottomMenu2 page={page} />
    </div>
  );
};

export default withTranslation()(JuraLive);

// import React, { useEffect, useRef } from 'react';
// import { withTranslation } from 'react-i18next';
// import { Row, Col } from 'react-bootstrap';
// import Header1 from './layout/Header1';
// import BottomMenu2 from './layout/BottomMenu2';
// import { MOVE_TO_HOMEPAGE_IN_SECONDS_LONG, getLangCode } from '../Utils/utils';
// import IdleTimer from 'react-idle-timer';

// const JuraLive = ({ t, i18n, history, ...props }) => {
//   const page = 'jura-live';
//   const animatedGif = useRef();

//   let idleTimer = null;

//   useEffect(() => {
//     animatedGif.current.src = require('../assets/animated-gif/jura-live.gif');
//     return () => {
//       animatedGif.current.src = require('../assets/animated-gif/jura-live.gif');
//     };
//   }, []);

//   const onIdle = (e) => {
//     console.log('user is idle', e);
//     console.log('last active', idleTimer.getLastActiveTime());
//     i18n.changeLanguage(getLangCode(window.machineName));
//     history.push('/');
//   };

//   return (
//     <div
//       style={{
//         height: '100vh',
//         width: '100vw',
//         overflow: 'hidden',
//         backgroundColor: '#F6F8F9',
//       }}
//     >
//       <IdleTimer
//         ref={(ref) => {
//           idleTimer = ref;
//         }}
//         element={document}
//         onIdle={onIdle}
//         debounce={250}
//         timeout={MOVE_TO_HOMEPAGE_IN_SECONDS_LONG}
//       />
//       <Header1 headerText={t('jura-live-what')} />
//       <Row style={{ height: '70%', width: '100%' }}>
//         <Col sm='4' className='align-vertical text-center'>
//           <span
//             className='jura-live-text1'
//             style={{
//               width: window.screen.availHeight === 1080 ? '13em' : '9em',
//             }}
//           >
//             {t('jura-live-text1')}
//           </span>
//           <span className='jura-live-text2'>{t('jura-live-text2')}</span>
//         </Col>
//         <Col sm='8' className='align-vertical text-right'>
//           <img className='pr-4 pt-5' ref={animatedGif} width='80%' alt='Jura Live Studio' />
//         </Col>
//       </Row>
//       <BottomMenu2 page={page} />
//     </div>
//   );
// };

// export default withTranslation()(JuraLive);
