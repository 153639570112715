import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setFlags } from "../../actions/callAct";
import { Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  checkForAgent,
  buildUrl,
  ALL_STUDIOS_WITH_LANGS,
  STUDIOS_WITH_ALLOWED_COUNTRIES,
  getCountryCode,
  getNameCode,
  CHECK_AVAILABILITY_SECONDS,
  getIdCode,
  getLangCode,
} from "../../Utils/utils";

import { setDefaultStudio, setLangStudio, setOtherStudiosAvail, setShowOtherButton, setChosenLngStudio } from "../../actions/callAct";

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Setup the interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const BottomMenu2 = ({
  t,
  i18n,
  history,
  setFlags,
  setDefaultStudio,
  setOtherStudiosAvail,
  setLangStudio,
  setShowOtherButton,
  setChosenLngStudio,
  defaultStudio,
  otherStudio,
  changeLanguageStudio,
  showOtherButton,
  ...props
}) => {
  const menuDiv = useRef();

  const [chosenLangStudioSt, setChosenLangStudioSt] = useState();

  const tabletCountry = getCountryCode(window.machineName);
  const tabletLanguage = getLangCode(window.machineName);
  const language = i18n.language;

  useEffect(() => {
    if (defaultStudio === "online") {
      menuDiv.current.style.margin = "0";
    } else if (showOtherButton || (defaultStudio === "online" && changeLanguageStudio === "offline")) {
      menuDiv.current.style.margin = "0";
    } else if (changeLanguageStudio === "online") {
      menuDiv.current.style.margin = "0";
    } else {
      menuDiv.current.style.margin = "auto";
    }
  }, [defaultStudio, showOtherButton, changeLanguageStudio]);

  //checks the studio availability based on CHECK_AVAILABILITY_SECONDS
  useInterval(() => {
    connectToStudio();
  }, CHECK_AVAILABILITY_SECONDS);

  const connectToStudio = async () => {
    // Checks the default studio is online or offline based on default language
    // and the tablet the country is in
    let flags = [];
    setChosenLangStudioSt(tabletCountry);
    const result = await checkForAgent(tabletLanguage, tabletCountry);
    if (result === "online") {
      setDefaultStudio(result);
      setChosenLngStudio(tabletCountry);
    } else {
      setDefaultStudio("offline");
      // If default studio is not available, check to see there are other studios available
      // or the same studio has other languages available and the country
      // the tablet is in is allowed to call that studio
      for (let [studio, langs] of Object.entries(ALL_STUDIOS_WITH_LANGS)) {
        for (const lang of langs) {
          const res = await checkForAgent(lang, studio);
          if (res === "online") {
            for (let [key, values] of Object.entries(STUDIOS_WITH_ALLOWED_COUNTRIES)) {
              if (key === studio) {
                if (values.indexOf(tabletCountry) !== -1) {
                  setOtherStudiosAvail({ studio, lang, res });
                  flags.push({ studio: studio, lang: lang });
                  setShowOtherButton(true);
                } else {
                  setOtherStudiosAvail("offline");
                  setShowOtherButton(false);
                }
              }
            }
          }
        }
      }
      setFlags(flags);
    }
  };

  // When lang changes, we first check to see if the country the tablet is in
  // is allowed to call the chosen language studio, if yes we check
  // if the studio is available
  const connectToStudioOnLangChange = async () => {
    let chosenLangStudios = [];
    let flags = [];
    for (let [key, values] of Object.entries(ALL_STUDIOS_WITH_LANGS)) {
      for (const lang of values) {
        if (lang === i18n.language) {
          // Studios that support the chosen language
          chosenLangStudios.push(key);
        }
      }
    }
    console.log("chosen lang studios: ", chosenLangStudios);
    // Check to see if the country the tablet is in is allowed to call the studios that support the chosen language
    for (const studio of chosenLangStudios) {
      console.log("chosen lang studios: ", studio);
      for (let [key, values] of Object.entries(STUDIOS_WITH_ALLOWED_COUNTRIES)) {
        if (key === studio) {
          for (const value of values) {
            if (value.indexOf(tabletCountry) !== -1) {
              console.log("can call studio");
              setChosenLangStudioSt(studio);
              try {
                const result = await checkForAgent(i18n.language, studio);
                if (result === "online") {
                  setLangStudio(result);
                  setChosenLngStudio(studio);
                  setShowOtherButton(false);
                } else {
                  setLangStudio("offline");

                  for (let [studio, langs] of Object.entries(ALL_STUDIOS_WITH_LANGS)) {
                    for (const lang of langs) {
                      const res = await checkForAgent(lang, studio);
                      if (res === "online") {
                        for (let [key, values] of Object.entries(STUDIOS_WITH_ALLOWED_COUNTRIES)) {
                          if (key === studio) {
                            if (values.indexOf(tabletCountry) !== -1) {
                              setOtherStudiosAvail({ studio, lang, res });
                              flags.push({ studio: studio, lang: lang });
                              setShowOtherButton(true);
                            } else {
                              setOtherStudiosAvail("offline");
                              setShowOtherButton(false);
                            }
                          }
                        }
                      }
                    }
                  }
                  setFlags(flags);
                }
              } catch (error) {
                console.log("An error happened!");
              }
            } else {
              // country the tablet is in can not call the chosen lang studio
              let flags = [];
              // If default studio is not available, check to see there are other studios available
              // or the same studio has other languages available and the country
              // the tablet is in is allowed to call that studio

              for (let [studio, langs] of Object.entries(ALL_STUDIOS_WITH_LANGS)) {
                if (studio === tabletCountry) {
                  for (const lang of langs) {
                    const res = await checkForAgent(lang, studio);
                    if (res === "online") {
                      setOtherStudiosAvail({ studio, lang, res });
                      flags.push({ studio: studio, lang: lang });
                      setShowOtherButton(true);
                      // If the chosen language is the language the country can take call directly
                      if (i18n.language === langs[0] || i18n.language === langs[1]) {
                        setDefaultStudio("online");
                        setShowOtherButton(false);
                        setOtherStudiosAvail("offline");
                      }
                    } else {
                      setDefaultStudio("offline");
                      setOtherStudiosAvail("offline");
                      setShowOtherButton(false);
                    }
                  }
                }
              }
              setFlags(flags);
              console.log("flags are: ", flags);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    connectToStudio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Checks the studio availability when the language changes without page refresh
  useEffect(() => {
    window.addEventListener("langChange", connectToStudioOnLangChange, true);
    return () => window.removeEventListener("langChange", connectToStudioOnLangChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onMenuClick = (menu) => {
    switch (menu) {
      case "home":
        history.push("/");
        break;
      case "jura-live":
        history.push("/jura-live");
        break;
      case "consultant":
        history.push("/consultant");
        break;
      case "about-jura":
        history.push("/about-jura");
        break;
      case "tech":
        history.push("/tech");
        break;
      case "qrcode":
        history.push("/qrcode");
        break;
      case "campaign":
        history.push("/campaign");
        break;
      default:
        return;
    }
  };

  const callBtnClick = (method) => {
    switch (method) {
      case "direct":
        const callUrl = buildUrl(
          tabletLanguage,
          tabletCountry,
          "POS",
          getNameCode(window.machineName),
          getIdCode(window.machineName),
          getCountryCode(window.machineName),
          "video",
          "live"
        );
        history.push({
          pathname: "/call",
          state: { url: callUrl },
        });
        console.log("call url: ", callUrl);
        break;
      case "flag":
        history.push("/flag-screen");
        break;
      case "changeLang":
        const changeLangCallUrl = buildUrl(
          language,
          chosenLangStudioSt,
          "POS",
          getNameCode(window.machineName),
          getIdCode(window.machineName),
          getCountryCode(window.machineName),
          "video",
          "live"
        );
        history.push({
          pathname: "/call",
          state: { url: changeLangCallUrl },
        });
        console.log("chosen language studio state: ", chosenLangStudioSt);
        console.log("change lang call url: ", changeLangCallUrl);
        break;

      default:
        return;
    }
  };

  return (
    <div className="bottom-menu2">
      <Row className="text-center">
        <div ref={menuDiv}>
          <div className="menu-div" onClick={(e) => onMenuClick("home")}>
            <img className="menu-image" src={require("../../assets/main/icons/home.png")} alt="home" />
            <span className="menu-title">{t("menu-home")}</span>
            <img
              style={{ display: props.page === "home" ? "block" : "none" }}
              className="menu-indicator"
              src={require("../../assets/menu-icons/indicator-red.png")}
              alt="indicator"
            />
          </div>
          {tabletCountry === "kw" ||
          tabletCountry === "lt" ||
          tabletCountry === "lv" ||
          tabletCountry === "cz" ||
          tabletCountry === "dk" ||
          tabletCountry === "ee" ||
          tabletCountry === "fi" ? (
            <></>
          ) : (
            <div className="menu-div" onClick={(e) => onMenuClick("jura-live")}>
              <img className="menu-image" src={require("../../assets/main/icons/jura-live.png")} alt="indicator" />
              <span className="menu-title">{t("menu-jura-live")}</span>
              <img
                style={{ display: props.page === "jura-live" ? "block" : "none" }}
                className="menu-indicator"
                src={require("../../assets/menu-icons/indicator-red.png")}
                alt="indicator"
              />
            </div>
          )}
          {/* <div className="menu-div" onClick={e => onMenuClick("consultant")}>
            <img
              className="menu-image"
              src={require("../../assets/menu-icons/your_jura.png")}
            />
            <span className="menu-title">{t("menu-your-jura")}</span>
            <img
              style={{
                display: props.page === "consultant" ? "block" : "none"
              }}
              className="menu-indicator"
              src={require("../../assets/menu-icons/indicator-red.png")}
            />
          </div> */}
          <div className="menu-div" onClick={(e) => onMenuClick("about-jura")}>
            <img className="menu-image" src={require("../../assets/main/icons/about-jura.png")} alt="about-jura" />
            <span className="menu-title">{t("menu-about-jura")}</span>
            <img
              style={{
                display: props.page === "about-jura" ? "block" : "none",
              }}
              className="menu-indicator"
              src={require("../../assets/menu-icons/indicator-red.png")}
            />
          </div>
          <div className="menu-div" onClick={(e) => onMenuClick("tech")}>
            <img className="menu-image" src={require("../../assets/main/icons/tech.png")} />
            <span className="menu-title">{t("menu-key-tech")}</span>
            <img
              style={{ display: props.page === "tech" ? "block" : "none" }}
              className="menu-indicator"
              src={require("../../assets/menu-icons/indicator-red.png")}
            />
          </div>
          <div className="menu-div" onClick={(e) => onMenuClick("campaign")}>
            <img className="menu-image" src={require("../../assets/main/icons/campaign.png")} />
            <span className="menu-title">{t("menu-campaign")}</span>
            <img
              style={{ display: props.page === "campaign" ? "block" : "none" }}
              className="menu-indicator"
              src={require("../../assets/menu-icons/indicator-red.png")}
            />
          </div>
          {tabletCountry === "kw" ||
          tabletCountry === "lt" ||
          tabletCountry === "lv" ||
          tabletCountry === "cz" ||
          tabletCountry === "dk" ||
          tabletCountry === "ee" ||
          tabletCountry === "fi" ? (
            <></>
          ) : (
            <div className="menu-div" onClick={(e) => onMenuClick("qrcode")}>
              <img className="menu-image" src={require("../../assets/main/icons/qrcode.png")} />
              <span className="menu-title">{t("menu-qrcode")}</span>
              <img
                style={{
                  display: props.page === "qrcode" ? "block" : "none",
                }}
                className="menu-indicator"
                src={require("../../assets/menu-icons/indicator-red.png")}
              />
            </div>
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          {((defaultStudio && defaultStudio === "online" && typeof changeLanguageStudio === "undefined") ||
            (defaultStudio === "online" && changeLanguageStudio === "online")) && (
            <button className="callBtn" onClick={(e) => callBtnClick("direct")}>
              <img
                style={{
                  marginRight: "20px",
                  width: "40px",
                  height: "40px",
                }}
                src={require("../../assets/main/icons/live-call.png")}
              />
              {t("bottom-menu-live-advice")}
            </button>
          )}
          {changeLanguageStudio === "online" && (
            <button className="callBtn" onClick={(e) => callBtnClick("changeLang")}>
              <img
                style={{
                  marginRight: "20px",
                  width: "40px",
                  height: "40px",
                }}
                src={require("../../assets/main/icons/live-call.png")}
              />
              {t("bottom-menu-live-advice")}
            </button>
          )}
          {(showOtherButton ||
            (defaultStudio === "online" && changeLanguageStudio === "offline") ||
            (defaultStudio === "offline" && otherStudio && otherStudio.res === "online")) && (
            <button className="callBtn" onClick={(e) => callBtnClick("flag")}>
              <img style={{ marginRight: "20px", width: "40px", height: "40px" }} src={require("../../assets/main/icons/live-call.png")} />
              {t("bottom-menu-live-advice")}
            </button>
          )}
        </div>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  defaultStudio: state.callReducer.defaultStudio,
  otherStudio: state.callReducer.otherStudioAvailable,
  changeLanguageStudio: state.callReducer.changeLanguageStudio,
  showOtherButton: state.callReducer.showOtherButton,
});

const mapDispatchToProps = {
  setFlags,
  setDefaultStudio,
  setLangStudio,
  setOtherStudiosAvail,
  setShowOtherButton,
  setChosenLngStudio,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(BottomMenu2)));
