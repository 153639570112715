import {
  SET_FLAGS,
  SET_DEFAULT_STUDIO,
  SET_CHANGE_LANGUAGE_STUDIO,
  SET_OTHER_STUDIOS_AVAILABLE,
  SET_SHOW_OTHER_BUTTON,
  SET_CHOSEN_LANG_STUDIO
} from "./types";

export const setFlags = flags => async dispatch => {
  try {
    dispatch({
      type: SET_FLAGS,
      payload: flags
    });
  } catch (error) {
    console.log(error);
  }
};

export const setDefaultStudio = defaultStudio => async dispatch => {
  try {
    dispatch({
      type: SET_DEFAULT_STUDIO,
      payload: defaultStudio
    });
  } catch (error) {
    console.log(error);
  }
};

export const setLangStudio = changeLangStudio => async dispatch => {
  try {
    dispatch({
      type: SET_CHANGE_LANGUAGE_STUDIO,
      payload: changeLangStudio
    });
  } catch (error) {
    console.log(error);
  }
};

export const setOtherStudiosAvail = otherStudios => async dispatch => {
  try {
    dispatch({
      type: SET_OTHER_STUDIOS_AVAILABLE,
      payload: otherStudios
    });
  } catch (error) {
    console.log(error);
  }
};

export const setShowOtherButton = otherButton => async dispatch => {
  try {
    dispatch({
      type: SET_SHOW_OTHER_BUTTON,
      payload: otherButton
    });
  } catch (error) {
    console.log(error);
  }
};

export const setChosenLngStudio = chosenLangStudio => async dispatch => {
  try {
    dispatch({
      type: SET_CHOSEN_LANG_STUDIO,
      payload: chosenLangStudio
    });
  } catch (error) {
    console.log(error);
  }
};
