import React, { useRef, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { MOVE_TO_HOMEPAGE_IN_SECONDS_LONG, getLangCode } from '../Utils/utils';
import Header1 from './layout/Header1';
import BottomMenu2 from './layout/BottomMenu2';
import IdleTimer from 'react-idle-timer';

const Tech = ({ t, i18n, history }) => {
  const page = 'tech';

  const content = useRef();
  const videoArea = useRef();

  const videoRef1 = useRef();
  const videoRef2 = useRef();
  const videoRef3 = useRef();
  const videoRef4 = useRef();
  const videoRef5 = useRef();

  let idleTimer = null;

  const onIdle = (e) => {
    i18n.changeLanguage(getLangCode(window.machineName));
    history.push('/');
  };

  const goBack = () => {
    videoRef1.current.pause();
    videoRef2.current.pause();
    videoRef3.current.pause();
    videoRef4.current.pause();
    videoRef5.current.pause();
    content.current.style.display = 'block';
    videoArea.current.style.display = 'none';
  };

  const playVideo = async (item) => {
    content.current.style.display = 'none';
    videoArea.current.style.display = 'block';

    switch (item) {
      case 'video1':
        videoRef2.current.style.display = 'none';
        videoRef3.current.style.display = 'none';
        videoRef4.current.style.display = 'none';
        videoRef5.current.style.display = 'none';
        videoRef1.current.style.display = 'block';

        videoRef1.current.setAttribute('src', `https://jura-contactless.com/videos/tablet-z10/${i18n.language}/iws.mp4`);
        videoRef1.current.currentTime = 0;
        videoRef1.current.volume = 0.7;
        try {
          await videoRef1.current.play();
        } catch (err) {
          videoRef1.current.setAttribute('src', 'https://jura-contactless.com/videos/tablet-z10/en/iws.mp4');
          videoRef1.current.play();
        }
        videoRef1.current.addEventListener('canplay', console.log('video can play but not sure it will play through.'));
        videoRef1.current.addEventListener('ended', videoFinished, false);
        break;
      case 'video2':
        videoRef1.current.style.display = 'none';
        videoRef3.current.style.display = 'none';
        videoRef4.current.style.display = 'none';
        videoRef5.current.style.display = 'none';
        videoRef2.current.style.display = 'block';

        videoRef2.current.setAttribute('src', `https://jura-contactless.com/videos/tablet-z10/${i18n.language}/prg.mp4`);

        videoRef2.current.currentTime = 0;
        videoRef2.current.volume = 0.7;
        try {
          await videoRef2.current.play();
        } catch (err) {
          videoRef2.current.setAttribute('src', 'https://jura-contactless.com/videos/tablet-z10/en/prg.mp4');
          videoRef2.current.play();
        }
        videoRef2.current.addEventListener('ended', videoFinished, false);
        break;
      case 'video3':
        videoRef1.current.style.display = 'none';
        videoRef2.current.style.display = 'none';
        videoRef4.current.style.display = 'none';
        videoRef5.current.style.display = 'none';
        videoRef3.current.style.display = 'block';

        videoRef3.current.setAttribute('src', `https://jura-contactless.com/videos/tablet-z10/${i18n.language}/brewing.mp4`);

        videoRef3.current.currentTime = 0;
        videoRef3.current.volume = 0.7;
        try {
          await videoRef3.current.play();
        } catch (err) {
          videoRef3.current.setAttribute('src', `https://jura-contactless.com/videos/tablet-z10/en/brewing.mp4`);
          videoRef3.current.play();
        }
        videoRef3.current.addEventListener('ended', videoFinished, false);
        break;
      case 'video4':
        videoRef1.current.style.display = 'none';
        videoRef2.current.style.display = 'none';
        videoRef3.current.style.display = 'none';
        videoRef5.current.style.display = 'none';
        videoRef4.current.style.display = 'block';

        videoRef4.current.setAttribute('src', `https://jura-contactless.com/videos/tablet-z10/${i18n.language}/milk-system.mp4`);

        videoRef4.current.currentTime = 0;
        videoRef4.current.volume = 0.7;
        try {
          await videoRef4.current.play();
        } catch (error) {
          videoRef4.current.setAttribute('src', 'https://jura-contactless.com/videos/tablet-z10/en/milk-system.mp4');
          videoRef4.current.play();
        }
        videoRef4.current.addEventListener('ended', videoFinished, false);
        break;
      case 'video5':
        videoRef1.current.style.display = 'none';
        videoRef2.current.style.display = 'none';
        videoRef3.current.style.display = 'none';
        videoRef4.current.style.display = 'none';
        videoRef5.current.style.display = 'block';

        videoRef5.current.setAttribute('src', `https://jura-contactless.com/videos/tablet-z10/${i18n.language}/preparation.mp4`);

        videoRef5.current.currentTime = 0;
        videoRef5.current.volume = 0.7;
        try {
          await videoRef5.current.play();
        } catch (error) {
          videoRef5.current.setAttribute('src', 'https://jura-contactless.com/videos/tablet-z10/en/preparation.mp4');
          videoRef5.current.play();
        }
        videoRef5.current.addEventListener('ended', videoFinished, false);
        break;

      default:
        break;
    }
  };

  const videoFinished = () => {
    videoArea.current.style.display = 'none';
    content.current.style.display = 'block';
  };

  return (
    <div className='full-screen' style={{ backgroundColor: '#F6F8F9' }}>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={MOVE_TO_HOMEPAGE_IN_SECONDS_LONG}
      />
      <Header1 headerText={t('tech-header')} />

      <Row ref={content}>
        <Row
          className='pl-5 pr-5 ml-5 mr-5'
          style={{
            marginTop: window.screen.availHeight === 1080 ? '5%' : '10%',
          }}
        >
          <Col>
            <div className='tech-menu'>
              <img className='tech-img' src={require('../assets/main/img/tech/Thermoblock.png')} alt='Jura Coffee Machine' />
              <h2 className='tech-title'>{t('tech-t2')}</h2>
              <p className='tech-paragraph'>{t('tech-p2')}</p>
              <img className='tech-play-btn' src={require('../assets/main/img/tech/play-btn.png')} onClick={(e) => playVideo('video5')} />
            </div>
          </Col>
          <Col>
            <div className='tech-menu'>
              <img className='tech-img' src={require('../assets/main/img/tech/Mahlwerk.png')} alt='Jura Coffee Machine' />
              <h2 className='tech-title'>{t('tech-t3')}</h2>
              <p className='tech-paragraph'>{t('tech-p3')}</p>
              <img className='tech-play-btn' src={require('../assets/main/img/tech/play-btn.png')} onClick={(e) => playVideo('video2')} />
            </div>
          </Col>
          <Col>
            <div className='tech-menu'>
              <img className='tech-img' src={require('../assets/main/img/tech/Filter.png')} alt='Jura Coffee Machine' />
              <h2 className='tech-title'>{t('tech-t1')}</h2>
              <p className='tech-paragraph'>{t('tech-p1')}</p>
              <img className='tech-play-btn' src={require('../assets/main/img/tech/play-btn.png')} onClick={(e) => playVideo('video1')} />
            </div>
          </Col>
        </Row>
        <Row className='mt-5 pl-5 pr-5 ml-5 mr-5'>
          <Col>
            <div className='tech-menu'>
              <img className='tech-img' src={require('../assets/main/img/tech/Brueheinheit.png')} alt='Jura Coffee Machine' />
              <h2 className='tech-title'>{t('tech-t4')}</h2>
              <p className='tech-paragraph'>{t('tech-p4')}</p>
              <img className='tech-play-btn' src={require('../assets/main/img/tech/play-btn.png')} onClick={(e) => playVideo('video3')} />
            </div>
          </Col>
          <Col>
            <div className='tech-menu'>
              <img className='tech-img' src={require('../assets/main/img/tech/Feinschaumduese.png')} alt='Jura Coffee Machine' />
              <h2 className='tech-title'>{t('tech-t5')}</h2>
              <p className='tech-paragraph'>{t('tech-p5')}</p>
              <img className='tech-play-btn' src={require('../assets/main/img/tech/play-btn.png')} onClick={(e) => playVideo('video4')} />
            </div>
          </Col>
          <Col>
            <div className='tech-menu' onClick={(e) => history.push('/resultat')}>
              <img className='tech-img' src={require('../assets/main/img/tech/Resultat.png')} alt='Jura Coffee Machine' />
              <h2 className='tech-title'>{t('tech-t6')}</h2>
              <p className='tech-paragraph'>{t('tech-p6')}</p>
            </div>
          </Col>
        </Row>
      </Row>
      <Row ref={videoArea} className='tech-video-area'>
        <ArrowLeft
          size={50}
          style={{
            position: 'absolute',
            left: '2em',
            top: '11em',
            zIndex: 999,
          }}
          onClick={(e) => goBack(e)}
        />
        <video
          ref={videoRef1}
          // poster={require("../assets/de_Intelligent_Water_System.jpg")}
          style={{
            objectFit: 'contain',
            // minWidth: '100vw',
            // minHeight: '74vh',
            width: '100vw',
            height: window.screen.availHeight === 1080 ? '74vh' : '77vh',
          }}
          controls
          controlsList='nofullscreen nodownload'
          preload='true'
          disablePictureInPicture
        ></video>
        <video
          ref={videoRef2}
          // poster={require("../assets/de_Das_Aroma_G3_Mahlwerk.jpg")}
          style={{
            objectFit: 'contain',
            // minWidth: "100vw",
            // minHeight: "74vh",
            width: '100vw',
            height: window.screen.availHeight === 1080 ? '74vh' : '77vh',
          }}
          controls
          controlsList='nofullscreen nodownload'
          preload='true'
          disablePictureInPicture
        ></video>
        <video
          ref={videoRef3}
          // poster={require("../assets/de_IPBAS_und_PEP.jpg")}
          style={{
            objectFit: 'contain',
            // minWidth: "100vw",
            // minHeight: "74vh",
            width: '100vw',
            height: window.screen.availHeight === 1080 ? '74vh' : '77vh',
          }}
          controls
          controlsList='nofullscreen nodownload'
          preload='true'
          disablePictureInPicture
        ></video>
        <video
          ref={videoRef4}
          // poster={require("../assets/de_IPBAS_und_PEP.jpg")}
          style={{
            objectFit: 'contain',
            // minWidth: "100vw",
            // minHeight: "74vh",
            width: '100vw',
            height: window.screen.availHeight === 1080 ? '74vh' : '77vh',
          }}
          controls
          controlsList='nofullscreen nodownload'
          preload='true'
          disablePictureInPicture
        ></video>
        <video
          ref={videoRef5}
          // poster={require("../assets/de_IPBAS_und_PEP.jpg")}
          style={{
            objectFit: 'contain',
            // minWidth: "100vw",
            // minHeight: "74vh",
            width: '100vw',
            height: window.screen.availHeight === 1080 ? '74vh' : '77vh',
          }}
          controls
          controlsList='nofullscreen nodownload'
          preload='true'
          disablePictureInPicture
        ></video>
      </Row>

      <BottomMenu2 page={page} />
    </div>
  );
};

export default withRouter(withTranslation()(Tech));
