import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { Container, Row, Col } from "react-bootstrap";
import Header1 from "./layout/Header1";
import BottomMenu2 from "./layout/BottomMenu2";
import { useInterval } from "./Home";
import {
  MOVE_TO_HOMEPAGE_IN_SECONDS_LONG,
  getLangCode,
  flags
} from "../Utils/utils";
import IdleTimer from "react-idle-timer";
import {
  getCountryCode,
  getNameCode,
  getIdCode,
  buildUrl
} from "../Utils/utils";

const QrCodeScreen = ({
  t,
  i18n,
  history,
  defaultStudio,
  otherStudio,
  changeLanguageStudio,
  chosenLangStudio
}) => {
  const page = "qrcode";

  const tabletCountry = getCountryCode(window.machineName);
  const language = i18n.language;

  let idleTimer = null;
  let callUrl;

  callUrl = buildUrl(
    language,
    // tabletCountry,
    chosenLangStudio,
    "POS",
    getNameCode(window.machineName),
    getIdCode(window.machineName),
    getCountryCode(window.machineName),
    "text",
    "live"
  );

  const onIdle = e => {
    i18n.changeLanguage(getLangCode(window.machineName));
    history.push("/");
  };

  return (
    <div className="full-screen" style={{ backgroundColor: "#F6F8F9" }}>
      <IdleTimer
        ref={ref => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={MOVE_TO_HOMEPAGE_IN_SECONDS_LONG}
      />
      <Header1 headerText={t("qr-code-header")} />
      {(defaultStudio &&
        defaultStudio === "online" &&
        typeof changeLanguageStudio === "undefined") ||
      (defaultStudio === "online" && changeLanguageStudio === "online") ||
      (defaultStudio === "offline" &&
        changeLanguageStudio === "online" &&
        otherStudio &&
        otherStudio.lang === i18n.language) ? (
        <Row className="align-items-center">
          <Col sm="7">
            <span className="qr-text1">{t("qr-code-text4")}</span>
            <span className="qr-text2">{t("qr-code-text5")}</span>
          </Col>
          <Col sm="3" className="text-left" style={{ marginTop: "10em" }}>
            <QRCode
              value={callUrl}
              size={400}
              style={{
                border: "45px solid #ffffff",
                boxShadow: "0px 20px 40px #DEDEDE",
                borderRadius: "10px"
              }}
            />
          </Col>
        </Row>
      ) : (defaultStudio === "online" && changeLanguageStudio === "offline") ||
        (defaultStudio === "offline" &&
          otherStudio &&
          otherStudio.res === "online" &&
          otherStudio.lang !== i18n.language) ? (
        history.push("/flag-screen-qr")
      ) : defaultStudio && defaultStudio === "offline" ? (
        <h3 className=".qr-text2 mt-5 text-center">
          {t("no-studio-available")}
        </h3>
      ) : (defaultStudio === "offline" &&
          typeof changeLanguageStudio === "undefined") ||
        (defaultStudio === "offline" && changeLanguageStudio === "offline") ? (
        <h3 className=".qr-text2 mt-5 text-center">
          {t("no-studio-available")}
        </h3>
      ) : defaultStudio === "offline" &&
        changeLanguageStudio === "online" &&
        otherStudio &&
        otherStudio.lang === i18n.language ? (
        ""
      ) : (
        ""
      )}
      <BottomMenu2 qrcode page={page} />
    </div>
  );
};

const mapStateToProps = state => ({
  defaultStudio: state.callReducer.defaultStudio,
  otherStudio: state.callReducer.otherStudio,
  changeLanguageStudio: state.callReducer.changeLanguageStudio,
  showOtherButton: state.callReducer.showOtherButton,
  chosenLangStudio: state.callReducer.chosenLangStudio,
  flags: state.callReducer.flags
});

export default connect(mapStateToProps)(withTranslation()(QrCodeScreen));
