import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import BottomMenu2 from './layout/BottomMenu2';
import LanguageSelector from './layout/LanguageSelector';
import Header1 from './layout/Header1';
import { MOVE_TO_HOMEPAGE_IN_SECONDS_LONG, getLangCode } from '../Utils/utils';
import IdleTimer from 'react-idle-timer';

const AboutJura = ({ t, i18n, history, ...props }) => {
  const page = 'about-jura';

  const [screenHeight, setScreenHeight] = useState();
  const [screenWidth, setScreenWidth] = useState();

  let idleTimer = null;

  useEffect(() => {
    setScreenHeight(window.screen.availHeight);
    setScreenWidth(window.screen.availWidth);
  }, []);

  const onIdle = (e) => {
    i18n.changeLanguage(getLangCode(window.machineName));
    history.push('/');
  };

  return (
    <div className='full-screen' style={{ backgroundColor: '#F6F8F9' }}>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={MOVE_TO_HOMEPAGE_IN_SECONDS_LONG}
      />
      <Header1 headerText={t('about-jura-header')} />
      <Carousel
        id='carousel'
        // indicators={false}
        // pauseOnHover
        interval={null}
        nextIcon={
          <img
            src={require('../assets/main/icons/arrow-right.png')}
            alt='next slide'
            style={{
              position: 'absolute',
              right: '2em',
            }}
          />
        }
        prevIcon={
          <img
            src={require('../assets/main/icons/arrow-left.png')}
            alt='previous slide'
            style={{
              position: 'absolute',
              left: '2em',
            }}
          />
        }
      >
        <Carousel.Item>
          {screenHeight === 1080 ? (
            <img className='d-block' src={require('../assets/main/img/Kaffeeresultat_Z10_Al-Wh_CBLMPrep_16-9.jpg')} alt='About Jura' />
          ) : (
            <img className='d-block' src={require('../assets/main/img/Kaffeeresultat_Z10_Al-Wh_CBLMPrep_4-3.jpg')} alt='About Jura' />
          )}

          <Carousel.Caption style={{ top: '10em', bottom: 'auto' }}>
            <h3
              style={{
                width: '50%',
                marginLeft: screenHeight === 1080 ? '17em' : '15em',
                marginTop: '-0.5em',
              }}
            >
              {t('about-jura-t1')}
            </h3>
          </Carousel.Caption>
          <Carousel.Caption style={{ top: '16em', bottom: 'auto' }}>
            <p
              style={{
                width: '50%',
                marginLeft: screenHeight === 1080 ? '24.3em' : '21.5em',
              }}
            >
              {t('about-jura-p1')}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {screenHeight === 1080 ? (
            <img className='d-block' src={require('../assets/main/img/JOE_ENA4_FMB_SmartPhone_19-6.jpg')} alt='Jura Coffee Machine' />
          ) : (
            <img className='d-block' src={require('../assets/main/img/JOE_ENA4_FMB_SmartPhone_4-3.jpg')} alt='Jura Coffee Machine' />
          )}

          <Carousel.Caption
            // className="animated fadeIn delay-halfs"
            style={{ top: '10em', bottom: 'auto' }}
          >
            <h3
              style={{
                width: '60%',
                marginLeft: screenHeight === 1080 ? '15.3em' : '14.5em',
              }}
            >
              {t('about-jura-t2')}
            </h3>
          </Carousel.Caption>
          <Carousel.Caption
            // clas sName="animated fadeIn delay-1s"
            style={{ top: '16em', bottom: 'auto' }}
          >
            <p
              style={{
                width: '48%',
                marginLeft: screenHeight === 1080 ? '22em' : '20.7em',
              }}
            >
              <span className='jura'>{t('about-jura-p2')}</span>
              <span className='trademark'>&#0174;</span>
              {t('about-jura-p2-1')}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {screenHeight === 1080 ? (
            <img
              className='d-block'
              src={require('../assets/main/img/Design_Z10_emo_Al-DaIn_beancontainer_16-9.jpg')}
              alt='Jura coffee machine'
            />
          ) : (
            <img
              className='d-block'
              src={require('../assets/main/img/Design_Z10_emo_Al-DaIn_beancontainer_4-3.jpg')}
              alt='Jura coffee machine'
            />
          )}

          <Carousel.Caption
            // className="animated fadeIn delay-halfs"
            style={{ top: '9em', bottom: 'auto' }}
          >
            <h3
              style={{
                width: '60%',
                marginLeft: screenHeight === 1080 ? '19.2em' : '15.8em',
              }}
            >
              {t('about-jura-t3')}
            </h3>
          </Carousel.Caption>
          <Carousel.Caption
            // className="animated fadeIn delay-1s"
            style={{ top: '15em', bottom: 'auto' }}
          >
            <p
              style={{
                width: screenHeight === 1080 ? '45%' : '40%',
                marginLeft: screenHeight === 1080 ? '27.5em' : '24.2em',
              }}
            >
              {t('about-jura-p3')}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {screenHeight === 1080 ? (
            <img className='d-block' src={require('../assets/main/img/Service_16zu9.jpg')} alt='Jura Coffee Machine' />
          ) : (
            <img className='d-block' src={require('../assets/main/img/Service_4zu3.jpg')} alt='Jura Coffee Machine' />
          )}

          <Carousel.Caption
            // className="animated fadeIn delay-halfs"
            style={{ top: '10em', bottom: 'auto' }}
          >
            <h3
              style={{
                width: '60%',
                marginLeft: screenHeight === 1080 ? '16.7em' : '12.8em',
              }}
            >
              {t('about-jura-t4')}
            </h3>
          </Carousel.Caption>
          <Carousel.Caption
            // className="animated fadeIn delay-1s"
            style={{ top: '16em', bottom: 'auto' }}
          >
            <p
              style={{
                width: '50%',
                marginLeft: screenHeight === 1080 ? '24em' : '19em',
              }}
            >
              {t('about-jura-p4')}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <BottomMenu2 slideShow page={page} />
    </div>
  );
};

export default withRouter(withTranslation()(AboutJura));
