import React, { useEffect, useState, useRef } from 'react';
import RightMenu from './layout/RightMenu';
import BottomMenu2 from './layout/BottomMenu2';
// import backgroundSm from '../assets/main/img/Home_4_3.png';
// import backgroundLg from '../assets/main/img/Home_16_9.png';
import backgroundSm from '../assets/main/img/Willkommen_RF_CBCof_4-3.jpg';
import backgroundLg from '../assets/main/img/Willkommen_RF_CBCof_16-9.jpg';
import { Row, Col, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import LanguageSelector from './layout/LanguageSelector';
import ReactSVG from 'react-svg';
import { getCountryCode } from '../Utils/utils';

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Setup the interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Home = ({ t, i18n, history }) => {
  const page = 'home';

  const tabletCountry = getCountryCode(window.machineName);

  return (
    <div
      style={{
        backgroundImage: window.screen.availHeight === 1080 ? `url(${backgroundLg})` : `url(${backgroundSm})`,
        backgroundSize: 'fill',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: window.screen.availHeight === 1080 ? '940px' : '1060px',
        width: window.screen.availHeight === 1080 ? '1920px' : '1600px',
        overflow: 'hidden',
      }}
    >
      <LanguageSelector home />
      <ReactSVG
        src={require('../assets/svg/jura_red_rgb.svg')}
        beforeInjection={(svg) => {
          svg.setAttribute('style', 'position: absolute; top: 10px; right: 50px; width: 14em; z-index:99;');
        }}
      />
      <Row
        style={{
          paddingTop: '7em',
          height: window.screen.availHeight === 1080 ? '940px' : '1060px',
          width: window.screen.availHeight === 1080 ? '1920px' : '1600px',
        }}
      >
        <Col sm='7' className='text-justify mt-5 pt-5 ml-1'>
          <div>
            <h3
              className='home-title'
              style={{
                marginLeft: window.screen.availHeight === 1080 ? '15.7em' : '13.5em',
              }}
            >
              {tabletCountry === 'kw' ||
              tabletCountry === 'lt' ||
              tabletCountry === 'lv' ||
              tabletCountry === 'cz' ||
              tabletCountry === 'dk' ||
              tabletCountry === 'ee' ||
              tabletCountry === 'fi'
                ? t('welcome-jura')
                : t('welcome-jura-live')}
            </h3>
            <p
              className='home-paragraph'
              style={{
                marginLeft: window.screen.availHeight === 1080 ? '39.3em' : '34em',
                width: window.screen.availHeight === 1080 ? '21em' : '19em',
              }}
            >
              {tabletCountry === 'kw' ||
              tabletCountry === 'lt' ||
              tabletCountry === 'lv' ||
              tabletCountry === 'cz' ||
              tabletCountry === 'dk' ||
              tabletCountry === 'ee' ||
              tabletCountry === 'fi'
                ? t('description-jura')
                : t('description')}
            </p>
            <p
              className='home-paragraph2'
              style={{
                marginLeft: window.screen.availHeight === 1080 ? '39.3em' : '34em',
                width: window.screen.availHeight === 1080 ? '21em' : '19em',
              }}
            >
              {t('hint-text')}
            </p>
          </div>
        </Col>
        <Col sm='4'></Col>
      </Row>
      <BottomMenu2 page={page} />
    </div>
  );
};

export default withTranslation()(Home);
