import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const styles = {
  menu: {
    width: "16.5em",
    // height: "auto",
    backgroundColor: "#ffffff",
    // borderRadius: "10px",
    cursor: "pointer",
    padding: "1.7em",
    marginBottom: "-0.3em",
    borderBottom: "1px solid #f0eded"
  }
};

const RightMenu = ({
  t,
  i18n,
  history,
  style,
  discoverMoreStyle,
  page,
  ...props
}) => {
  let [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = useRef();

  useEffect(() => {
    window.addEventListener("click", closeMenu);
    return () => {
      window.removeEventListener("click", closeMenu);
    };
  }, []);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleClick = e => {
    e.stopPropagation();
    // toggleMenu.current.style.borderRadius = 0;
    setMenuOpen(!menuOpen);
    // history.push("/menu-screen");
  };

  const onMenuClick = menu => {
    switch (menu) {
      case "jura-live":
        history.push("/jura-live");
        break;
      case "consultant":
        history.push("/consultant");
        break;
      case "about-jura":
        history.push("/about-jura");
        break;
      case "tech":
        history.push("/tech");
        break;
      case "qrcode":
        history.push("/qrcode");
        break;
      case "news":
        history.push("/news");
        break;
      default:
        return;
    }
  };

  return (
    <div>
      {menuOpen && (
        <div
          id="menu"
          style={
            page === "about-jura" || page === "tech"
              ? {
                  boxShadow: "none"
                }
              : {
                  boxShadow: "0px -6px 5px 6px #ccc",
                  borderRadius: "10px"
                }
          }
        >
          <div
            className="text-center"
            style={styles.menu}
            onClick={e => onMenuClick("jura-live")}
          >
            <Row>
              <Col>
                <img
                  id="jura-live-icon"
                  src={
                    page === "jura-live"
                      ? require("../../assets/jura-live-red.png")
                      : require("../../assets/jura-live-grey.png")
                  }
                  alt="What is Jura Live?"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <span
                  id="jura-live-text"
                  className="text-center right-menu-text"
                  style={{
                    color: page === "jura-live" ? "#C31924" : "#ccccd5"
                  }}
                >
                  {t("menu-jura-live")}
                </span>
              </Col>
            </Row>
          </div>
          <div
            className="text-center"
            style={styles.menu}
            onClick={e => onMenuClick("consultant")}
          >
            <Row>
              <Col>
                <img
                  id="consultant-icon"
                  src={
                    page === "consultant"
                      ? require("../../assets/check-mark-red.png")
                      : require("../../assets/check-mark-grey.png")
                  }
                  alt="Jura Live consultant"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <span
                  id="consultant-text"
                  className="text-center right-menu-text"
                  style={{
                    color: page === "consultant" ? "#C31924" : "#ccccd5"
                  }}
                >
                  {t("menu-consultant")}
                </span>
              </Col>
            </Row>
          </div>
          <div
            className="text-center"
            style={styles.menu}
            onClick={e => onMenuClick("about-jura")}
          >
            <Row>
              <Col>
                <img
                  id="about-jura-icon"
                  src={
                    page === "about-jura"
                      ? require("../../assets/about-jura-red.png")
                      : require("../../assets/about-jura-grey.png")
                  }
                  alt="About Jura"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <span
                  id="about-jura-text"
                  className="text-center right-menu-text"
                  style={{
                    color: page === "about-jura" ? "#C31924" : "#ccccd5"
                  }}
                >
                  {t("menu-about-jura")}
                </span>
              </Col>
            </Row>
          </div>
          <div
            className="text-center"
            style={styles.menu}
            onClick={e => onMenuClick("tech")}
          >
            <Row>
              <Col>
                <img
                  id="tech-icon"
                  src={
                    page === "tech"
                      ? require("../../assets/tech-icon-red.png")
                      : require("../../assets/tech-icon-grey.png")
                  }
                  alt="Key Technologies"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <span
                  id="tech-text"
                  className="text-center right-menu-text"
                  style={{ color: page === "tech" ? "#C31924" : "#ccccd5" }}
                >
                  {t("menu-key-tech")}
                </span>
              </Col>
            </Row>
          </div>
          <div
            className="text-center"
            style={styles.menu}
            onClick={e => onMenuClick("qrcode")}
          >
            <Row>
              <Col>
                <img
                  src={
                    page === "qrcode"
                      ? require("../../assets/qrcode-red.png")
                      : require("../../assets/qrcode.png")
                  }
                  alt="QR Code"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <span
                  className="text-center right-menu-text"
                  style={{
                    color: page === "qrcode" ? "#C31924" : "#ccccd5"
                  }}
                >
                  {t("qr-code-right-menu")}
                </span>
              </Col>
            </Row>
          </div>
          <div
            className="text-center"
            style={styles.menu}
            onClick={e => onMenuClick("news")}
          >
            <Row>
              <Col>
                <img
                  src={
                    page === "news"
                      ? require("../../assets/news-icon-red.png")
                      : require("../../assets/news-icon-grey.png")
                  }
                  alt="News"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <span
                  className="text-center right-menu-text"
                  style={{
                    color: page === "news" ? "#C31924" : "#ccccd5"
                  }}
                >
                  {t("menu-news")}
                </span>
              </Col>
            </Row>
          </div>
        </div>
      )}
      <div
        className="text-center"
        ref={toggleMenu}
        style={{
          width: "16.5em",
          height: "6em",
          backgroundColor: "#ffffff",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          cursor: "pointer",
          display: "block"
        }}
        onClick={e => handleClick(e)}
      >
        <Row className="pt-2">
          <Col>
            <svg width="60" height="50" viewBox="0 0 46 30" fill="none">
              <path
                d="M42.9072 12.7165L42.9111 12.7128L31.0557 1.26938C30.2351 0.477123 29.141 0.0393574 27.975 0.0368652C27.9715 0.0368652 27.9682 0.0368652 27.9647 0.0368652C26.8026 0.0368652 25.7101 0.469646 24.888 1.25606L23.4397 2.64155C23.2813 2.79305 23.0962 2.81565 23.0001 2.81565C22.904 2.81565 22.7189 2.79305 22.5606 2.64155L21.1121 1.25597C20.29 0.469646 19.1976 0.0369511 18.0356 0.0369511C18.0321 0.0369511 18.0286 0.0369511 18.0253 0.0369511C16.8592 0.0394433 15.7651 0.477209 14.9445 1.26947L3.08919 12.7128L3.09305 12.7165C1.18279 14.5464 0 17.0709 0 19.8549C0 25.4286 4.74061 29.9631 10.5676 29.9631C14.6957 29.9631 18.2761 27.686 20.0144 24.3772C20.8791 24.9014 21.9027 25.2063 23.0001 25.2063C24.0974 25.2063 25.121 24.9014 25.9858 24.3772C27.7241 27.686 31.3045 29.9631 35.4326 29.9631C41.2594 29.963 46 25.4285 46 19.8549C46 17.0709 44.8172 14.5464 42.9072 12.7165ZM10.5676 26.3954C6.79722 26.3954 3.72977 23.4614 3.72977 19.8549C3.72977 16.2485 6.79722 13.3144 10.5676 13.3144C14.338 13.3144 17.4054 16.2485 17.4054 19.8549C17.4054 23.4614 14.338 26.3954 10.5676 26.3954ZM23 22.2332C21.6267 22.2332 20.5135 21.1685 20.5135 19.8548C20.5135 18.5412 21.6266 17.4764 23 17.4764C24.3734 17.4764 25.4865 18.5412 25.4865 19.8548C25.4865 21.1685 24.3733 22.2332 23 22.2332ZM35.4324 26.3954C31.662 26.3954 28.5946 23.4614 28.5946 19.8549C28.5946 16.2485 31.662 13.3144 35.4324 13.3144C39.2028 13.3144 42.2702 16.2485 42.2702 19.8549C42.2702 23.4614 39.2028 26.3954 35.4324 26.3954Z"
                fill="#CCCCD5"
              />
            </svg>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="text-center right-menu-text p-2">
              {t("menu-discover-more")}
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(withTranslation()(RightMenu));
