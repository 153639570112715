import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { getLangCode } from "../Utils/utils";

import Backend from "i18next-xhr-backend";

// Set a machine name for testing,
// TODO: Delete for production
// window.machineName = "CH # de # NBS Demo Elo Switzerland media market # H163-004-938";

// if window.machineName is null or does not include hash(#) use default name
if (window.machineName == null) {
  window.machineName = "CH # de # Default Name # Serial Number";
} else if (window.machineName.includes("#") === false) {
  window.machineName = "CH # de # Default Name # Serial Number";
}

// function to set the machine details based on ip address
// if (window.machineName == null) {
//   fetch(
//     "https://api.ipstack.com/check?access_key=bfb78181104d7b364595b0de94497a2e"
//   )
//     .then(results => {
//       return results.json();
//     })
//     .then(data => {
//       console.log(data);
//       let country_code = data["country_code"];
//       let language = data.location.languages[0].code;
//       let tabletName = "Tablet Name Unkown";
//       window.machineName = `${country_code} # ${language} # ${tabletName}`;
//       console.log("data: ", country_code);
//       console.log("data: ", language);
//       console.log(window.machineName);
//     });
// }

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    lng: getLangCode(window.machineName),
    debug: true,
    options: {
      // order and from where user language should be detected
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag", "path", "subdomain"],
      // keys or params to lookup language from
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      //   cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

// Dispatches a new event when the language manually changes
i18n.on("languageChanged", () => {
  window.dispatchEvent(new Event("langChange"));
});

export default i18n;
