import {
  SET_FLAGS,
  SET_DEFAULT_STUDIO,
  SET_CHANGE_LANGUAGE_STUDIO,
  SET_OTHER_STUDIOS_AVAILABLE,
  SET_SHOW_OTHER_BUTTON,
  SET_CHOSEN_LANG_STUDIO
} from "../actions/types";

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_FLAGS:
      return {
        ...state,
        flags: payload
      };
    case SET_DEFAULT_STUDIO:
      return {
        ...state,
        defaultStudio: payload
      };
    case SET_OTHER_STUDIOS_AVAILABLE:
      return {
        ...state,
        otherStudio: payload
      };
    case SET_CHANGE_LANGUAGE_STUDIO:
      return {
        ...state,
        changeLanguageStudio: payload
      };
    case SET_SHOW_OTHER_BUTTON:
      return {
        ...state,
        showOtherButton: payload
      };
    case SET_CHOSEN_LANG_STUDIO:
      return {
        ...state,
        chosenLangStudio: payload
      };

    default:
      return state;
  }
};
