import React from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import LanguageSelector from "./LanguageSelector";
import ReactSVG from "react-svg";

const Header1 = ({ t, i18n, headerText }) => {
  return (
    <Row style={{ backgroundColor: "#ffffff", height: "140px" }}>
      <Col sm="3">
        <LanguageSelector />
      </Col>
      <Col sm="6" className="text-center" style={{ paddingTop: "36px" }}>
        <span className="ml-4 header-title">{headerText}</span>
      </Col>
      <Col sm="3" className="text-right">
        <ReactSVG
          src={require("../../assets/svg/jura_red_rgb.svg")}
          beforeInjection={svg => {
            svg.setAttribute(
              "style",
              "position: absolute; top: 10px; right: 50px; width: 14em; z-index:99;"
            );
          }}
        />
      </Col>
    </Row>
  );
};

export default withTranslation()(Header1);
