import React from "react";
import { withTranslation } from "react-i18next";
import Header1 from "./layout/Header1";
import BottomMenu2 from "./layout/BottomMenu2";
import { Row, Col } from "react-bootstrap";

const Thanks = ({ t, i18n, history }) => {
  const goToStartPage = () => {
    history.push("/");
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden"
      }}
    >
      <Header1 headerText={t("feedback-live-consulting")} />
      <Row
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#F6F8F9",
          margin: 0
        }}
      >
        <Col>
          <Row className="mt-5">
            <Col className="mt-5">
              <p className="title mt-5">{t("thanks-title")}</p>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5">
              <p className="paragraph mt-5">{t("thanks-p")}</p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="mt-5">
              <button className="cbtn btn-next" onClick={e => goToStartPage()}>
                {t("thanks-btn")}
              </button>
            </Col>
          </Row>
        </Col>
        <Col className="mr-0 p-0">
          <img
            src={require("../assets/thanks.jpeg")}
            alt="coffee"
            style={{
              width: "50vw",
              height: "90vh",
              borderTopLeftRadius: "10em"
            }}
          />
        </Col>
      </Row>
      <BottomMenu2 slideShow noLiveAdvice />
    </div>
  );
};

export default withTranslation()(Thanks);
