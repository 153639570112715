import React from "react";
import { Modal, Container, Button, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import ReactSVG from "react-svg";
import { MOVE_TO_HOMEPAGE_IN_SECONDS, getLangCode } from "../../Utils/utils";
import IdleTimer from "react-idle-timer";

const LanguageSelectorModal = props => {
  const { t, i18n, history, tReady, ...rest } = props;

  let idleTimer = null;

  const handleClick = e => {
    props.onHide();
  };

  const changeLang = lang => {
    i18n.changeLanguage(lang);
    props.onHide();
  };

  const onIdle = e => {
    console.log("user is idle", e);
    console.log("last active", idleTimer.getLastActiveTime());
    i18n.changeLanguage(getLangCode(window.machineName));
    props.onHide();
  };

  return (
    <Modal
      {...rest}
      centered
      animation={true}
      backdrop={"static"}
      dialogClassName="jura-live-modal"
    >
      <IdleTimer
        ref={ref => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={MOVE_TO_HOMEPAGE_IN_SECONDS}
      />
      <Modal.Header closeButton></Modal.Header>
      <Container className="text-center" style={{ height: "80%" }}>
        <Row className="mt-4 h-100 align-items-center">
          <Col onClick={e => changeLang("en")}>
            <ReactSVG
              src={require("../../assets/flags/en.svg")}
              beforeInjection={svg => {
                svg.setAttribute("style", "width: 15em; padding: 1.5em;");
              }}
            />
            <span className="flag-name">{t("lang-selector-en")}</span>
          </Col>
          <Col onClick={e => changeLang("de")}>
            <ReactSVG
              src={require("../../assets/flags/de.svg")}
              beforeInjection={svg => {
                svg.setAttribute("style", "width: 15em; padding: 1.5em;");
              }}
            />
            <span className="flag-name">{t("lang-selector-de")}</span>
          </Col>
          <Col onClick={e => changeLang("fr")}>
            <ReactSVG
              src={require("../../assets/flags/fr.svg")}
              beforeInjection={svg => {
                svg.setAttribute("style", "width: 15em; padding: 1.5em;");
              }}
            />
            <span className="flag-name">{t("lang-selector-fr")}</span>
          </Col>
        </Row>
      </Container>
      <Modal.Footer className="lang-selector-modal-footer">
        <button className="btn-cancel-call" onClick={e => handleClick()}>
          {t("call-close-modal")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default withTranslation()(LanguageSelectorModal);
