import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Row } from "react-bootstrap";
import BottomMenu2 from "./layout/BottomMenu2";
import Header1 from "./layout/Header1";
import { MOVE_TO_HOMEPAGE_IN_SECONDS_LONG, getLangCode } from "../Utils/utils";
import IdleTimer from "react-idle-timer";

const Thermo = ({ t, i18n, history, ...props }) => {
  const page = "tech";

  const [screenHeight, setScreenHeight] = useState();
  const [screenWidth, setScreenWidth] = useState();

  let idleTimer = null;

  useEffect(() => {
    setScreenHeight(window.screen.availHeight);
    setScreenWidth(window.screen.availWidth);
  }, []);

  const onIdle = e => {
    i18n.changeLanguage(getLangCode(window.machineName));
    history.push("/");
  };

  return (
    <div className="full-screen" style={{ backgroundColor: "#F6F8F9" }}>
      <IdleTimer
        ref={ref => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={MOVE_TO_HOMEPAGE_IN_SECONDS_LONG}
      />
      <Header1 headerText={t("tech-header")} />
      <Row>
        <img
          src={require("../assets/main/icons/arrow-left.png")}
          alt="previous slide"
          style={{
            position: "absolute",
            left: "2em",
            top: "11em",
            zIndex: 999
          }}
          onClick={e => history.push("/tech")}
        />
        {screenHeight === 1080 ? (
          <img
            className="d-block w-100"
            src={require("../assets/main/img/Thermoblock_19_6.jpg")}
            alt="About Jura"
          />
        ) : (
          <img
            className="d-block w-100"
            src={require("../assets/main/img/Thermoblock_4_3.jpg")}
            alt="About Jura"
          />
        )}

        <div style={{ position: "absolute", top: "17em", right: "20em" }}>
          <h3
            className="text-title"
            style={{
              width: "55%",
              marginLeft: screenHeight === 1080 ? "20em" : "17em"
            }}
          >
            {t("thermo-title")}
          </h3>
          <p
            className="text-desc mt-5"
            style={{
              width: "50%",
              marginLeft: screenHeight === 1080 ? "28.5em" : "24.5em"
            }}
          >
            {t("thermo-desc")}
          </p>
        </div>
      </Row>

      <BottomMenu2 slideShow page={page} />
    </div>
  );
};

export default withRouter(withTranslation()(Thermo));
