import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Header1 from "./layout/Header1";

const Feedback = ({ t, i18n, history }) => {
  const [feedbackRate, setFeedbackRate] = useState();

  useEffect(() => {
    console.log("feedback: ", feedbackRate);
  }, [feedbackRate]);

  const deselectAllRateImg = () => {
    const img = document.getElementById("feedback-col").children;
    for (let item of img) {
      item.style.opacity = 0.5;
    }
  };

  const handleClick = star => {
    switch (star) {
      case "1":
        setFeedbackRate(1);
        deselectAllRateImg();
        document.getElementById("red").style.opacity = 1.0;
        break;
      case "2":
        setFeedbackRate(2);
        deselectAllRateImg();
        document.getElementById("orange").style.opacity = 1.0;
        break;
      case "3":
        setFeedbackRate(3);
        deselectAllRateImg();
        document.getElementById("yellow").style.opacity = 1.0;
        break;
      case "4":
        setFeedbackRate(4);
        deselectAllRateImg();
        document.getElementById("green").style.opacity = 1.0;
        break;
      case "5":
        setFeedbackRate(5);
        deselectAllRateImg();
        document.getElementById("dark-green").style.opacity = 1.0;
        break;
      default:
        return;
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden"
      }}
    >
      <Header1 headerText={t("feedback-live-consulting")} />
      <Row
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#F6F8F9",
          margin: 0
        }}
      >
        <Col className="text-center">
          <Row className="mt-5">
            <Col className="mt-5">
              <p className="title">{t("feedback-title")}</p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <p className="paragraph">{t("feedback-p1")}</p>
            </Col>
          </Row>
          <Row className="w-100 feedback-smiley mt-5">
            <Col id="feedback-col">
              <img
                id="red"
                src={require("../assets/red-b.png")}
                onClick={e => handleClick("1")}
                alt="red"
              />
              <img
                id="orange"
                src={require("../assets/orange-b.png")}
                onClick={e => handleClick("2")}
                alt="orange"
              />
              <img
                id="yellow"
                src={require("../assets/yellow-b.png")}
                onClick={e => handleClick("3")}
                alt="yellow"
              />
              <img
                id="green"
                src={require("../assets/green-b.png")}
                onClick={e => handleClick("4")}
                alt="green"
              />
              <img
                id="dark-green"
                src={require("../assets/dark-green-b.png")}
                onClick={e => handleClick("5")}
                alt="dark-green"
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <p className="paragraph">{t("feedback-p2")}</p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <button className="cbtn btn-next" style={{ marginRight: "1em" }}>
                {t("feedback-btn-yes")}
              </button>
              <button className="cbtn btn-next">
                {t("feedback-btn-not-yet")}
              </button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <button className="cbtn" onClick={e => history.push("/")}>
                {t("feedback-btn-over")}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation()(Feedback);
