import React, { useState } from 'react';
import { Dropdown, DropdownButton, Row, Col, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import LanguageSelectorModal from './LanguageSelectorModal';

const LanguageSelector = ({ t, i18n, ...props }) => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className='bg-transparent' style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
      {props.slideShow ? (
        <ReactSVG
          src={require('../../assets/svg/jura_red_rgb.svg')}
          beforeInjection={(svg) => {
            svg.setAttribute('style', 'position: absolute; top: 24px; left: 24px; width: 14em; z-index:99;');
          }}
        />
      ) : (
        ''
      )}

      <Dropdown>
        <Dropdown.Toggle>
          {props.slideShow ? (
            <Row>
              <Col>
                <img
                  style={{
                    width: '60px',
                    height: '60px',
                    marginLeft: '1em',
                    marginTop: '1em',
                  }}
                  src={require('../../assets/globe-white.png')}
                  alt='language selector'
                />
              </Col>
              <Col>
                <p
                  style={{
                    textTransform: 'uppercase',
                    color: '#ffffff',
                    fontSize: '1.8em',
                    fontWeight: 'bold',
                    marginTop: '0.5em',
                    marginLeft: '-0.5em',
                    lineHeight: '64px',
                  }}
                >
                  {i18n.language}
                </p>
              </Col>
            </Row>
          ) : props.home ? (
            <Row>
              <Col>
                <img
                  style={{
                    width: '60px',
                    height: '60px',
                    marginLeft: '1em',
                    marginTop: '1.9em',
                  }}
                  src={require('../../assets/globe-white.png')}
                  alt='language selector'
                />
              </Col>
              <Col>
                <p
                  style={{
                    textTransform: 'uppercase',
                    color: '#ffffff',
                    fontSize: '1.8em',
                    marginTop: '1em',
                    marginLeft: '-0.5em',
                    fontWeight: 'bold',
                    lineHeight: '64px',
                  }}
                >
                  {i18n.language}
                </p>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <img
                  style={{
                    width: '60px',
                    height: '60px',
                    marginLeft: '2em',
                    marginTop: '1.9em',
                  }}
                  src={require('../../assets/globe.png')}
                  alt='language selector'
                />
              </Col>
              <Col>
                <p
                  style={{
                    textTransform: 'uppercase',
                    color: '#666D7B',
                    fontSize: '1.8em',
                    marginTop: '1em',
                    marginLeft: '-0.5em',
                    fontWeight: 'bold',
                    lineHeight: '64px',
                  }}
                >
                  {i18n.language}
                </p>
              </Col>
            </Row>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className={props.home ? 'dropdownMenu-white' : 'dropdownMenu-grey'}>
          <img
            className='arrow-up'
            src={props.home ? require('../../assets/main/icons/polygon1.png') : require('../../assets/main/icons/polygon1-grey.png')}
          />
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('en')}>
            EN
          </Dropdown.Item>
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('de')}>
            DE
          </Dropdown.Item>
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('fr')}>
            FR
          </Dropdown.Item>
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('nl')}>
            NL
          </Dropdown.Item>
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('pl')}>
            PL
          </Dropdown.Item>
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('fi')}>
            FI
          </Dropdown.Item>
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('dk')}>
            DK
          </Dropdown.Item>
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('lv')}>
            LV
          </Dropdown.Item>
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('lt')}>
            LT
          </Dropdown.Item>
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('ee')}>
            EE
          </Dropdown.Item>
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('cz')}>
            CZ
          </Dropdown.Item>
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('ru')}>
            RU
          </Dropdown.Item>
          <Dropdown.Item className={props.home ? 'dropdown-item' : 'dropdown-item-white'} onClick={(e) => changeLanguage('se')}>
            SE
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default withTranslation()(LanguageSelector);
