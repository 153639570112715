import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import Header1 from './layout/Header1';
import BottomMenu2 from './layout/BottomMenu2';
import { MOVE_TO_HOMEPAGE_IN_SECONDS_LONG, getLangCode } from '../Utils/utils';
import IdleTimer from 'react-idle-timer';

const Campaign = ({ t, i18n, history, ...props }) => {
  const page = 'campaign';

  // const [vidPlaying, setVidPlaying] = useState();

  // const animatedGif = useRef();
  const videoRef1 = useRef();
  // const playBtn = useRef();

  useEffect(() => {
    try {
      videoRef1.current.setAttribute('src', `https://jura-contactless.com/videos/tablet-z10/${i18n.language}/hot-cold.mp4`);
    } catch (err) {
      videoRef1.current.setAttribute('src', 'https://jura-contactless.com/videos/tablet-z10/en/hot-cold.mp4');
    }

    window.addEventListener('langChange', changeVidOnLangChange);

    return () => {
      window.removeEventListener('langChange', changeVidOnLangChange);
    };
  }, []);

  const changeVidOnLangChange = () => {
    try {
      videoRef1.current.setAttribute('src', `https://jura-contactless.com/videos/tablet-z10/${i18n.language}/hot-cold.mp4`);
    } catch (err) {
      videoRef1.current.setAttribute('src', 'https://jura-contactless.com/videos/tablet-z10/en/hot-cold.mp4');
    }
  };

  let idleTimer = null;

  // useEffect(() => {
  //   animatedGif.current.src = require("../assets/animated-gif/jura-live.gif");
  //   return () => {
  //     animatedGif.current.src = require("../assets/animated-gif/jura-live.gif");
  //   };
  // }, []);

  const onIdle = (e) => {
    console.log('user is idle', e);
    console.log('last active', idleTimer.getLastActiveTime());
    i18n.changeLanguage(getLangCode(window.machineName));
    history.push('/');
  };

  // const playVideo = () => {
  //   playBtn.current.style.display = "none";
  //   videoRef1.current.volume = 0.3;
  //   videoRef1.current.play();
  //   setVidPlaying(true);
  // };

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        backgroundColor: '#F6F8F9',
      }}
    >
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={MOVE_TO_HOMEPAGE_IN_SECONDS_LONG}
      />
      <Header1 headerText={t('campaign-header')} />
      <Row style={{ height: '70%', width: '100%' }}>
        <Col sm='4' className='align-vertical text-center'>
          <span className='campaign-text1'>{t('campaign-text1')}</span>
          <span className='campaign-text2'>{t('campaign-text2')}</span>
        </Col>
        <Col sm='8' className='align-vertical text-right p-0 m-0' style={{ position: 'relative' }}>
          {/* <img
            ref={playBtn}
            src={require("../assets/main/img/playVid.png")}
            style={{
              width: "100px",
              height: "100px",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-50px",
              marginLeft: "50px",
              zIndex: 999
            }}
            onClick={e => playVideo()}
          /> */}
          <video
            ref={videoRef1}
            poster={require('../assets/main/img/hot-cold.png')}
            style={{
              objectFit: 'contain',
              minHeight: '74vh',
              minWidth: '65vh',
              width: '65vw',
              height: window.screen.availHeight === 1080 ? '74vh' : '77vh',
            }}
            controls
            controlsList='nofullscreen nodownload'
            preload='true'
            disablePictureInPicture
          >
            {/* <source
              src={require("../assets/video/vids/Jura_ENA8_16x9_10s_D_ut_D_v017_R128_Original_28145.mp4")}
              type="video/mp4"
            /> */}
          </video>
        </Col>
      </Row>
      <BottomMenu2 page={page} />
    </div>
  );
};

export default withTranslation()(Campaign);
