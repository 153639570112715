import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Header1 from "../components/layout/Header1";
import IdleTimer from "react-idle-timer";
import BottomMenu2 from "./layout/BottomMenu2";
import ReactSVG from "react-svg";

import {
  flags,
  buildUrl,
  getCountryCode,
  getNameCode,
  getIdCode,
  getLangCode,
  MOVE_TO_HOMEPAGE_IN_SECONDS_LONG,
} from "../Utils/utils";

const FlagScreenQr = ({ t, i18n, history, flags, ...props }) => {
  const page = "flag-screen-qr";

  let idleTimer = null;

  const tabletCountry = getCountryCode(window.machineName);

  const onImgClick = (e, item) => {
    const callUrl = buildUrl(
      item.lang,
      item.studio,
      "POS",
      getNameCode(window.machineName),
      getIdCode(window.machineName),
      getCountryCode(window.machineName),
      "text",
      "live"
    );

    history.push({
      pathname: "/flag-screen-qr2",
      state: { url: callUrl },
    });
  };

  const onIdle = (e) => {
    i18n.changeLanguage(getLangCode(window.machineName));
    history.push("/");
  };

  return (
    <div className="full-screen" style={{ backgroundColor: "#ffffff" }}>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={MOVE_TO_HOMEPAGE_IN_SECONDS_LONG}
      />
      <Header1 headerText={t("choose-language-header-text")} />
      <Container className="text-center" style={{ height: "70%" }}>
        <Row className="mt-4">
          {flags && flags.length > 0 ? (
            <Col>
              <h3>
                {t("choose-language-text1")}
                {i18n.language.toUpperCase()}
              </h3>
              <h3>{t("choose-language-text3")}</h3>
            </Col>
          ) : (
            <Col>
              <h3>{t("no-studio-available")}</h3>
            </Col>
          )}
        </Row>
        <Row className="mt-5 pt-5 align-items-center">
          {flags && flags.length > 0
            ? flags.map((item, index) => (
                <Col key={index}>
                  <ReactSVG
                    src={require(`../assets/flags/${item.lang}.svg`)}
                    beforeInjection={(svg) => {
                      svg.setAttribute("style", "width: 15em; padding: 1.5em;");
                    }}
                    onClick={(e) => onImgClick(e, item)}
                  />
                  <span className="flag-name">{item.lang.toUpperCase()}</span>
                </Col>
              ))
            : ""}
        </Row>
        <BottomMenu2 page={page} />
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  flags: state.callReducer.flags,
});

export default connect(mapStateToProps)(withTranslation()(FlagScreenQr));
