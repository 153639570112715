import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { setLangStudio } from "../actions/callAct";
import Header1 from "../components/layout/Header1";
import BottomMenu2 from "./layout/BottomMenu2";
import { Container, Row, Col } from "react-bootstrap";
import IdleTimer from "react-idle-timer";
import {
  MOVE_TO_HOMEPAGE_IN_SECONDS_LONG,
  getNameCode,
  getIdCode,
  getCountryCode,
  buildUrl
} from "../Utils/utils";

const FlagScreenQr2 = props => {
  const {
    t,
    i18n,
    history,
    changeLanguageStudio,
    setLangStudio,
    chosenLangStudio
  } = props;
  let callUrl = props.location.state.url;

  let idleTimer = null;

  const onIdle = e => {
    history.push("/");
  };

  useEffect(() => {
    // Sets the language studio online so the page shows content on first load
    setLangStudio("online");
  }, []);

  const changeCallUrl = () => {
    console.log("callurl changed");
    callUrl = buildUrl(
      i18n.language,
      chosenLangStudio,
      "POS",
      getNameCode(window.machineName),
      getIdCode(window.machineName),
      getCountryCode(window.machineName),
      "text",
      "live"
    );
  };

  // Change the call url when the language changes
  useEffect(() => {
    window.addEventListener("langChange", changeCallUrl, true);
    return () => window.removeEventListener("langChange", changeCallUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div className="full-screen" style={{ backgroundColor: "#F6F8F9" }}>
      <IdleTimer
        ref={ref => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={MOVE_TO_HOMEPAGE_IN_SECONDS_LONG}
      />
      <Header1 headerText={t("qr-code-header")} />
      {changeLanguageStudio === "online" ? (
        <Row className="align-items-center">
          <Col sm="7">
            <span className="qr-text1">{t("qr-code-text4")}</span>
            <span className="qr-text2">{t("qr-code-text5")}</span>
          </Col>
          <Col sm="3" className="text-left" style={{ marginTop: "10em" }}>
            <QRCode
              value={callUrl}
              size={400}
              style={{
                border: "45px solid #ffffff",
                boxShadow: "0px 20px 40px #DEDEDE",
                borderRadius: "10px"
              }}
            />
          </Col>
        </Row>
      ) : (
        <Col className="text-center mt-5">
          <h3>{t("no-studio-available")}</h3>
        </Col>
      )}
      <BottomMenu2 />
    </div>
  );
};

const mapStateToProps = state => ({
  changeLanguageStudio: state.callReducer.changeLanguageStudio,
  chosenLangStudio: state.callReducer.chosenLangStudio
});

const mapDispatchToProps = {
  setLangStudio
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FlagScreenQr2));
