import jsonp from "jsonp";

export const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

export const getImage = (imageData) => {
  let image = new Image();
  image.src = imageData;
  console.log("converted image ", image);
  return document.body.appendChild(image);
};

const processMachineName = (machineName) => {
  if (machineName !== null) {
    if (machineName.indexOf("#") !== -1) {
      let arr_machinename = machineName.split("#");
      for (var i in arr_machinename) {
        arr_machinename[i] = arr_machinename[i].trim();
      }
      return arr_machinename;
    }
  }
};

export const getCountryCode = (machineName) => {
  const machineDetails = processMachineName(machineName);
  return machineDetails[0].toLowerCase();
};

export const getLangCode = (machineName) => {
  const machineDetails = processMachineName(machineName);
  return machineDetails[1].toLowerCase();
};

export const getNameCode = (machineName) => {
  const machineDetails = processMachineName(machineName);
  return machineDetails[2].replace(/\s+/g, "-");
};

export const getIdCode = (machineName) => {
  const machineDetails = processMachineName(machineName);
  return machineDetails[3];
};

// export const buildCallUrl = (
//   originID,
//   country,
//   topicID,
//   lang,
//   name,
//   method
// ) => {
//   if (method === "test") {
//     return `https://live.jura-cloud.com/site/index.php?originID=${originID}&country=${country}&topicID=${topicID}_${lang}&lang=${lang}&name=${name}&test=1`;
//   }
//   if (method === "live")
//     return `https://live.jura-cloud.com/site/index.php?originID=${originID}&country=${country}&topicID=${topicID}_${lang}&lang=${lang}&name=${name}`;
// };

// // Checks to see if there are agents available for the particular language (Old Software)
// export const checkForAvailableAgent = (topicId, serviceUrl, serviceId) => {
//   return new Promise((resolve, reject) => {
//     const serviceInfo = new window.adiaLive.ServiceInfo({
//       topicId: topicId, // set here the topicId which you want to listen to
//       OnError: e => {
//         // react to error message (optional)
//         reject();
//       },
//       OnServiceStateChange: e => {
//         if (e.ConnectedAdvisers > 0) {
//           // there are advisers online for given topicId
//           resolve(true);
//         } else {
//           resolve(false);
//         }
//       }
//     });
//     serviceInfo.connect(serviceUrl, serviceId); // ServiceUrl, ServiceId -> Use JURALIVE-DEV-02 for Test-Service and JURALIVE-DEV-01 for productive Service
//   });
// };

export const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

// All the flags(studios) that can get calls from the countries listed
export const flags = {
  en: ["ch", "de", "at", "fr", "be", "lu", "dk", "cz", "pl", "ee", "fi", "lt", "se", "es", "ru"],
  de: ["ch", "de", "at", "fr", "be", "lu", "dk", "es"],
  fr: ["ch", "de", "at", "fr", "be", "lu", "dk", "es"],
  pl: ["pl", "ru", "cz", "lt", "de"],
  ru: ["ru", "pl", "cz", "ee", "lt", "fi"],
  au: ["au"],
  cn: ["cn"],
  sg: ["sg"],
  za: ["za"],
  nl: ["nl"],
};

// Link to studio
// export const LINK_TO_STUDIO_EU = "https://al01.adia.tv";
// export const LINK_TO_STUDIO_US = "https://al02.adia.tv";
// export const LINK_TO_STUDIO_AUS_SG = "https://al05.adia.tv";
// export const LINK_TO_STUDIO_CN = "https://al06.adia.tv";

// // Service ID
// export const SERVICE_ID_EU = "JURALIVE-DEV-01";
// export const SERVICE_ID_US = "JURALIVE-DEV-01";
// export const SERVICE_ID_AUS_SG = "JURALIVE-AUS-01";
// export const SERVICE_ID_CN = "JURALIVE-CN-01";
// export const SERVICE_ID_TEST = "JURALIVE-DEV-02";

// All Studios
export const ALL_STUDIOS_FLAGS = ["en", "de", "fr", "ru", "pl", "nl"];

// Number of seconds to wait before making the call to a live studio
export const WAIT_BEFORE_MAKING_CALL = 10000;

// Number of seconds to check the studio availability
export const CHECK_AVAILABILITY_SECONDS = 30000;

// Number of seconds before moving to homepage
export const MOVE_TO_HOMEPAGE_IN_SECONDS = 120000;

// Number of seconds before moving to homepage
export const MOVE_TO_HOMEPAGE_IN_SECONDS_LONG = 120000;

// Number of seconds to close calling modal after the call is finished
export const MOVE_TO_HOMEPAGE_IN_SECONDS_VLONG = 240000;

//  _   _                 _____        __ _
// | \ | |               /  ___|      / _| |
// |  \| | _____      __ \ `--.  ___ | |_| |___      ____ _ _ __ ___
// | . ` |/ _ \ \ /\ / /  `--. \/ _ \|  _| __\ \ /\ / / _` | '__/ _ \
// | |\  |  __/\ V  V /  /\__/ / (_) | | | |_ \ V  V / (_| | | |  __/
// \_| \_/\___| \_/\_/   \____/ \___/|_|  \__| \_/\_/ \__,_|_|  \___|

export const buildUrl = (language, country, origin, posName, posID, posCountry, chatmode, method) => {
  if (method === "test" && chatmode === "video") {
    return `https://live-eu.juralive.io/files/frame.html?language=${language}&country=${country}&origin=${origin}&posName=${posName}&posID=${posID}&posCountry=${posCountry}&topic=sales&fullscreen=false&mute=false&chat=false&chatmode=${chatmode}`;
  }

  if (method === "live" && chatmode === "video") {
    switch (country) {
      case "ch":
      case "de":
      case "at":
      case "fr":
      case "pl":
      case "au":
      case "uk":
      case "be":
      case "cz":
      case "dk":
      case "ee":
      case "fi":
      case "lt":
      case "lv":
      case "nl":
      case "se":
      case "ru":
      case "cn":
      case "za":
      case "us":
      case "ca":
        return `https://domestic.jura-live.com/pos/?origin=pos&mute=false&fullscreen=false&country=${country}&language=${language}`;
      default:
        return;
    }
  }

  if (method === "live" && chatmode === "text") {
    switch (country) {
      case "ch":
      case "de":
      case "at":
      case "fr":
      case "pl":
      case "au":
      case "uk":
      case "be":
      case "cz":
      case "dk":
      case "ee":
      case "fi":
      case "lt":
      case "lv":
      case "nl":
      case "se":
      case "ru":
      case "cn":
      case "za":
      case "us":
      case "ca":
        return `https://domestic.jura-live.com/pos/?origin=pos&mute=false&fullscreen=false&country=${country}&language=${language}`;
      default:
        return;
    }
  }
};

// Checks to see if there are agents available for the particular language (New Software)
export const checkForAgent = (language, country) => {
  switch (country) {
    case "ch":
    case "de":
    case "at":
    case "fr":
    case "pl":
    case "au":
    case "uk":
    case "be":
    case "cz":
    case "dk":
    case "ee":
    case "fi":
    case "lt":
    case "lv":
    case "nl":
    case "se":
    case "ru":
    case "cn":
    case "za":
    case "us":
    case "ca":
      return new Promise((resolve, reject) => {
        fetch(`https://domestic.jura-live.com/pos/?status=json&country=${country}&language=${language}`)
          .then((response) => response.json())
          .then((data) => {
            resolve(data.status);
          });
      });

    default:
      return;
  }
};

// List of all Studios with their support language
export const ALL_STUDIOS_WITH_LANGS = {
  ch: ["de", "fr"],
  nl: ["en", "nl"],
  pl: ["pl"],
  ru: ["ru"],
  ca: ["en", "fr"],
  chn: ["cn"],
  usa: ["en"],
  za: ["en"],
  sg: ["en"],
  au: ["en"],
};

// List of studios that can get calls from specified countries
export const STUDIOS_WITH_ALLOWED_COUNTRIES = {
  ch: ["ch", "de", "at", "fr", "be", "lu"],
  nl: [
    // "ch",
    // "de",
    // "at",
    // "fr",
    "be",
    // "lu",
    // "dk",
    // "cz",
    // "pl",
    // "ee",
    // "fi",
    // "lt",
    // "se",
    // "es",
    // "ru",
    "uk",
    "nl",
  ],
  pl: [
    "pl",
    // "ch",
    // "ru",
    // "cz",
    // "lt",
    // "de"
  ],
  ru: [
    "ru",
    //"pl",
    //"cz",
    //"ee",
    //"lt",
    //"fi"
  ],
  ca: ["ca"],
  chn: ["cn"],
  usa: ["usa"],
  za: ["za"],
  sg: ["sg"],
  au: ["au"],
};
