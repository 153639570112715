import React from "react";

const CoffeeMachineSVG = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="650.000000pt"
      height="715.000000pt"
      viewBox="0 0 650.000000 715.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,715.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M3830 6639 c-63 -4 -116 -12 -118 -17 -2 -6 -20 -8 -42 -5 -80 11
-1077 -63 -1510 -111 -130 -15 -351 -43 -407 -52 -40 -6 -38 4 -58 -254 -21
-281 -34 -715 -42 -1427 -5 -532 -4 -605 9 -620 13 -15 16 -79 22 -408 3 -214
15 -563 26 -775 11 -212 20 -402 20 -422 l0 -38 50 0 50 0 0 -55 c0 -60 -5
-56 83 -70 51 -8 150 14 143 32 -3 7 1 13 9 13 8 0 15 7 15 16 0 9 -5 12 -12
8 -7 -4 -8 -3 -4 4 4 7 11 12 16 12 10 0 31 -56 23 -63 -15 -16 180 -27 230
-14 28 8 29 9 25 69 l-3 60 61 -5 c99 -10 184 -44 196 -80 2 -4 23 -1 48 8 24
9 76 17 115 18 l70 2 3 -61 c3 -60 4 -62 34 -68 47 -9 106 -7 113 4 3 6 1 10
-5 10 -7 0 -10 7 -7 14 3 8 2 38 -2 65 l-8 51 61 0 c34 0 66 -4 71 -8 12 -9
18 -32 7 -32 -13 0 -13 -103 0 -112 16 -9 75 -10 157 -1 l63 6 -4 70 -3 69 95
-5 c93 -5 177 -25 213 -52 17 -12 19 -36 24 -257 3 -139 1 -267 -5 -298 l-10
-55 -1 83 -1 82 -29 0 c-16 0 -41 -4 -54 -9 -14 -6 -53 -13 -86 -17 -58 -6
-61 -6 -61 15 0 14 -4 20 -12 15 -6 -4 -28 -6 -49 -6 -21 1 -69 -9 -106 -22
-55 -20 -94 -25 -208 -29 -124 -4 -189 -14 -185 -27 1 -3 -3 -16 -8 -30 -24
-62 -35 -390 -17 -503 4 -26 14 -55 22 -62 11 -12 12 -15 1 -16 -7 0 7 -7 32
-15 31 -10 109 -16 255 -20 115 -3 210 -6 212 -8 2 -1 -3 -34 -10 -72 l-12
-69 -115 -5 c-115 -6 -115 -6 -40 -11 57 -4 39 -6 -72 -7 -100 -1 -148 -6
-148 -13 0 -7 -7 -8 -18 -4 -9 3 -39 11 -67 16 -51 10 -92 39 -81 57 4 6 15 9
26 7 22 -4 69 -36 70 -47 0 -5 19 -7 43 -7 l42 2 -35 6 c-26 5 -34 11 -32 24
3 17 -24 64 -33 60 -3 -2 -25 -3 -49 -3 -44 0 -45 1 -71 54 -41 84 -68 104
-151 112 l-70 7 -14 -44 c-8 -24 -15 -49 -15 -56 0 -16 -36 -53 -51 -53 -19 0
-27 41 -15 73 6 15 8 27 5 27 -3 0 2 10 10 22 25 36 20 39 -46 34 -59 -5 -58
-3 0 10 13 2 20 8 17 13 -6 12 39 23 59 15 9 -3 23 -1 31 6 11 9 19 9 28 2 6
-6 28 -12 47 -15 32 -3 31 -4 -10 -6 -63 -2 -31 -19 43 -22 45 -2 61 -7 79
-26 23 -24 45 -32 31 -10 -4 6 -9 23 -10 37 -1 14 -11 70 -22 125 -11 55 -30
184 -43 287 -21 174 -24 187 -45 197 -19 9 -23 18 -23 55 0 25 -5 48 -10 51
-6 4 -8 16 -4 27 6 19 3 20 -22 14 -16 -4 -33 -12 -39 -18 -7 -7 -2 -8 15 -4
24 6 24 6 -3 -9 -15 -9 -25 -20 -22 -25 3 -4 -8 -11 -25 -14 -99 -19 -90 -21
-90 18 0 19 -4 37 -9 40 -14 9 -196 -12 -225 -25 -20 -10 -23 -15 -15 -26 9
-10 7 -13 -7 -14 -18 0 -18 -1 1 -8 l20 -8 -20 -6 c-11 -3 -77 -9 -147 -12
-71 -4 -128 -10 -129 -14 0 -3 -2 -17 -4 -31 -2 -14 -4 -45 -5 -70 -1 -25 -6
-70 -12 -99 -6 -30 -10 -123 -9 -205 1 -177 1 -186 14 -186 5 0 7 -6 4 -14 -4
-10 10 -17 56 -25 93 -16 286 -23 339 -13 55 11 98 5 93 -12 -2 -6 -9 -39 -15
-73 l-13 -63 -196 -1 c-114 -1 -179 -5 -156 -9 29 -5 33 -8 15 -9 -23 -2 -24
-3 -5 -11 17 -7 16 -9 -10 -9 -39 -1 -51 8 -53 39 -3 55 -12 70 -40 71 -81 4
-85 7 -116 67 -13 26 -34 56 -46 67 l-23 20 7 -36 6 -36 -87 14 c-49 7 -178
32 -287 53 l-199 40 -252 -75 c-138 -41 -257 -75 -263 -75 -7 0 -12 -4 -12 -9
0 -12 133 -41 535 -116 85 -16 297 -46 455 -65 135 -16 149 -19 128 -31 -9 -5
-19 -22 -23 -37 l-6 -28 -67 8 c-420 53 -849 122 -1107 178 -77 17 -164 36
-193 42 -28 6 -52 14 -52 18 0 4 132 44 293 90 160 46 357 102 437 126 148 45
216 53 267 33 15 -6 114 -30 218 -54 152 -34 191 -39 193 -28 3 9 10 6 24 -12
43 -55 44 -28 4 150 -14 61 -35 184 -46 275 -18 134 -25 168 -41 184 -11 10
-22 16 -24 15 -6 -7 15 -265 30 -354 18 -110 29 -151 39 -147 4 2 17 -13 27
-32 22 -41 25 -70 5 -44 -7 10 -16 15 -19 11 -4 -3 -12 -1 -19 4 -7 6 -51 20
-98 32 -92 24 -165 48 -192 62 -32 17 -64 10 -76 -15 -10 -22 -13 -23 -50 -13
-36 9 -77 -1 -537 -136 -274 -81 -502 -150 -506 -154 -8 -10 7 -153 19 -165
27 -27 588 -141 1017 -207 110 -17 214 -33 231 -36 29 -5 33 -11 49 -68 42
-154 55 -159 525 -221 521 -69 864 -103 1429 -141 272 -19 317 -15 426 34 59
27 143 89 218 160 l38 36 409 0 c226 0 410 -3 410 -7 0 -5 63 -8 139 -8 l139
0 85 93 c416 454 706 802 842 1007 l110 165 7 130 c11 208 10 2222 -2 2733
-15 674 -27 984 -37 991 -5 3 -191 136 -413 295 -222 158 -410 291 -417 293
-11 4 -13 -165 -11 -913 2 -1501 26 -2779 73 -3997 7 -197 11 -365 8 -373 -6
-17 -26 -22 -121 -27 l-74 -4 4 111 c3 110 3 111 -21 114 -13 2 -26 12 -28 23
-7 27 -83 66 -93 48 -11 -17 -147 -26 -523 -34 -181 -3 -340 -10 -353 -15 -31
-12 -30 -19 -44 295 -6 143 -15 352 -20 465 -41 907 -50 1490 -49 2914 1 749
2 1377 3 1398 l0 36 174 7 173 7 -178 101 c-98 55 -202 113 -232 128 -58 32
-61 32 -261 18z m-748 -1607 c10 -10 15 -103 20 -395 4 -210 9 -423 11 -474 4
-87 3 -92 -17 -97 -21 -5 -21 -9 -18 -203 1 -109 7 -225 13 -257 9 -52 2 -428
-15 -704 -3 -58 -14 -72 -58 -72 l-28 0 0 375 c0 327 -2 375 -15 375 -13 0
-15 -48 -15 -375 l0 -375 -47 0 c-27 0 -53 4 -59 8 -17 11 -38 590 -22 610 11
13 10 14 -8 8 -19 -6 -22 -2 -26 33 -3 35 1 43 29 65 17 14 34 26 37 26 4 0 6
-22 6 -50 0 -45 7 -60 22 -45 2 3 2 27 -2 55 -5 36 -3 51 7 54 15 6 16 68 6
309 -5 121 -9 157 -20 157 -11 0 -13 85 -13 480 l0 480 -52 0 c-28 0 -48 3
-44 7 23 23 285 28 308 5z m-628 -11 c33 -13 1 -23 -62 -20 -31 1 -72 2 -89 1
l-33 -2 0 -470 c0 -387 2 -470 13 -470 16 0 27 -144 27 -356 0 -129 0 -130 28
-156 15 -14 38 -31 52 -38 15 -8 20 -15 13 -17 -21 -7 -86 18 -125 48 -21 18
-42 27 -52 23 -11 -4 -16 -1 -16 10 0 9 5 16 10 16 6 0 10 83 10 229 l0 229
-30 7 -30 7 0 469 0 469 27 11 c14 5 31 8 37 6 6 -2 31 -1 56 3 60 10 140 10
164 1z m151 -182 c119 -30 193 -167 165 -303 -40 -195 -253 -268 -380 -130
-53 58 -72 106 -72 184 -1 82 27 146 88 199 65 57 118 70 199 50z m-415 -1328
c0 -5 13 -14 29 -19 38 -13 495 -16 520 -3 17 10 18 7 13 -37 -2 -26 -6 -48
-7 -50 -2 -2 -61 -6 -133 -9 l-130 -6 -6 -191 c-3 -105 -5 -225 -3 -266 l2
-75 -31 -3 -31 -3 -9 272 c-7 220 -12 273 -23 277 -12 4 -13 -36 -8 -272 l6
-276 -58 0 -59 0 -6 58 c-3 31 -10 136 -16 232 -6 96 -13 194 -16 218 -5 41
-6 42 -45 42 -24 0 -39 5 -40 13 0 6 -2 23 -5 36 -3 16 3 32 18 48 22 24 38
30 38 14z m1070 -325 c0 -189 5 -212 43 -225 37 -13 133 -3 148 15 22 27 39
16 39 -26 0 -30 -5 -42 -17 -47 -10 -3 -34 -19 -54 -35 -30 -22 -47 -28 -88
-28 -46 0 -52 3 -66 30 -10 19 -23 30 -37 30 -20 0 -20 4 -14 61 3 34 6 133 6
220 0 152 1 157 20 152 19 -5 20 -14 20 -147z m170 20 c0 -73 3 -153 6 -178 6
-44 6 -45 -23 -50 -16 -2 -35 -2 -41 0 -13 4 -18 343 -5 355 3 4 19 7 35 7
l28 0 0 -134z m125 -1938 c-3 -18 -16 -61 -27 -96 -15 -48 -26 -66 -45 -73
-38 -14 -45 -11 -39 19 7 37 42 132 47 129 2 -1 2 1 -1 5 -3 4 -23 3 -45 -2
-22 -5 -49 -9 -60 -9 -11 1 -1 5 22 11 24 5 42 14 40 21 -1 8 13 12 40 12 22
0 45 3 49 8 17 16 25 5 19 -25z m1648 -50 c-5 -7 -72 -95 -147 -195 l-138
-183 -297 0 c-163 0 -406 4 -541 10 -135 5 -339 12 -455 17 -149 5 -190 9
-140 13 39 3 138 2 220 -1 83 -4 231 -8 330 -10 l180 -3 3 36 c3 34 5 36 49
42 75 10 391 7 398 -4 3 -5 22 -10 42 -10 28 0 42 8 70 38 52 55 153 179 153
187 0 4 -12 4 -27 0 -60 -15 -1184 0 -1213 16 -8 5 108 6 262 2 l276 -6 4 24
3 24 310 3 c171 1 317 4 325 6 8 2 89 4 179 5 129 1 161 -2 154 -11z"
        />
        <path
          d="M2935 4708 c3 -178 10 -446 16 -596 5 -150 9 -329 9 -398 0 -109 2
-124 17 -124 15 0 16 51 9 668 -3 367 -9 691 -12 720 -6 42 -10 52 -26 52 -18
0 -18 -11 -13 -322z"
        />
        <path
          d="M2485 4795 c-49 -17 -107 -80 -123 -133 -14 -46 -16 -132 -3 -132 11
0 71 67 71 79 0 5 9 11 21 14 15 4 19 2 15 -9 -4 -11 -2 -12 14 -4 26 14 30
13 30 -7 1 -17 1 -17 14 0 16 21 40 23 32 2 -9 -24 12 -17 25 8 25 46 50 44
44 -5 l-4 -31 22 22 c13 11 35 21 50 21 25 0 27 -3 21 -27 -4 -16 -8 -30 -9
-33 -2 -3 -29 -5 -62 -4 -48 1 -58 4 -57 18 2 16 0 16 -15 2 -20 -18 -82 -21
-98 -5 -8 8 -24 -2 -57 -35 -53 -52 -52 -61 8 -114 42 -37 103 -55 151 -46 76
14 163 112 152 170 -2 13 0 24 4 24 13 0 11 46 -3 93 -17 55 -82 122 -133 136
-50 14 -61 13 -110 -4z"
        />
        <path
          d="M4567 5727 c-61 -37 -91 -90 -95 -170 -5 -82 15 -145 59 -181 34 -29
38 -42 8 -26 -11 7 -23 9 -26 7 -3 -3 20 -17 51 -32 59 -28 129 -40 210 -37
213 9 317 271 158 402 -40 33 -110 60 -155 60 l-30 0 23 -20 c26 -23 15 -27
-14 -5 -31 24 -53 19 -58 -14 -3 -22 -1 -27 12 -24 42 11 98 -95 84 -160 -30
-140 -162 -184 -246 -82 -53 64 -31 199 39 236 13 7 23 18 23 25 0 7 3 19 6
28 8 22 -4 20 -49 -7z"
        />
        <path
          d="M1851 2361 c-5 -4 -5 -28 2 -60 l10 -54 81 6 c122 10 126 12 113 53
-6 21 -18 36 -31 40 -39 9 -166 20 -175 15z"
        />
        <path
          d="M2193 2352 c-55 -10 -83 -20 -83 -29 0 -7 5 -13 12 -13 6 0 1 -9 -12
-20 l-23 -20 55 0 c30 0 97 -3 148 -7 88 -6 92 -5 87 13 -3 10 -8 36 -12 57
-6 36 -7 37 -48 36 -23 -1 -79 -9 -124 -17z"
        />
        <path
          d="M2850 2261 c0 -56 19 -84 53 -77 78 16 76 14 84 59 9 42 9 42 -26 54
-20 6 -53 12 -73 12 l-38 1 0 -49z"
        />
        <path
          d="M3109 2293 c-10 -2 -14 -14 -13 -36 1 -17 -2 -38 -8 -44 -8 -10 16
-13 119 -13 l130 0 -3 38 c-2 20 -3 43 -3 50 -1 9 -28 12 -103 11 -57 -1 -110
-3 -119 -6z"
        />
        <path
          d="M4375 1618 c-11 -6 -26 -14 -34 -17 -19 -9 -44 -89 -36 -120 3 -14 6
-32 7 -39 1 -7 15 -23 32 -36 27 -20 42 -23 119 -24 48 0 90 4 93 9 3 5 9 7
14 4 4 -3 31 0 58 6 45 11 51 15 63 51 11 35 10 40 -9 61 -12 13 -22 27 -22
31 0 13 -44 45 -61 46 -9 0 -24 10 -33 23 -10 14 -14 16 -10 5 7 -20 -6 -24
-22 -8 -15 15 -85 22 -99 10 -9 -8 -15 -8 -20 0 -8 12 -14 12 -40 -2z"
        />
      </g>
    </svg>
  );
};

export default CoffeeMachineSVG;
